import { useState } from 'react';
import { Space, Switcher } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { FigmaEmbed, ComponentLinks, P } from "../../../../src/common";
import * as React from 'react';
export default {
  useState,
  Space,
  Switcher,
  Playground,
  Props,
  FigmaEmbed,
  ComponentLinks,
  P,
  React
};