import React from 'react';
import { Link as HseLink } from '@hse-design/react';
import { Link as GatsbyLink } from 'gatsby';

export function FooterLinkComponent({ children, href = '#', to }) {
  if (to) {
    return (
      <HseLink component={GatsbyLink} to={to} variant="secondaryInvert">
        {children}
      </HseLink>
    );
  }
  return (
    <HseLink href={href} variant="secondaryInvert">
      {children}
    </HseLink>
  );
}
