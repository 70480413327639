import { Attention, ContentAttention } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from "../../../../src/common";
import * as React from 'react';
export default {
  Attention,
  ContentAttention,
  Playground,
  Props,
  ComponentLinks,
  React
};