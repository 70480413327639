export const sidebarWidthTabletLargeFixed = "272px";
export const sidebarWidthDesktopSmallFixed = "272px";
export const sidebarWidthDesktopMediumFixed = "344px";
export const sidebarWidthDesktopLargeFixed = "344px";
export const sidebarWidthTabletLargeDynamic = "72px";
export const sidebarWidthDesktopSmallDynamic = "72px";
export const sidebarWidthDesktopMediumDynamic = "72px";
export const sidebarWidthDesktopLargeDynamic = "72px";
export const sidebarWidthTabletLargeStatic = "272px";
export const sidebarWidthDesktopSmallStatic = "272px";
export const sidebarWidthDesktopMediumStatic = "272px";
export const sidebarWidthDesktopLargeStatic = "272px";