import { Playground, Props } from 'docz';
import { ToastContainer, toast } from "react-toastify";
import { Button, Space, Toast, Size, ToastCloseButton, IconGlobalMessage, AlertLink } from '@hse-design/react';
import { colorSystemError, colorSystemSuccess, colorSystemWarning } from "@hse-design/tokens";
import { FigmaEmbed, ComponentLinks } from "../../../../src/common";
import * as React from 'react';
export default {
  Playground,
  Props,
  ToastContainer,
  toast,
  Button,
  Space,
  Toast,
  Size,
  ToastCloseButton,
  IconGlobalMessage,
  AlertLink,
  colorSystemError,
  colorSystemSuccess,
  colorSystemWarning,
  FigmaEmbed,
  ComponentLinks,
  React
};