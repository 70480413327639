import upperFirst from 'lodash/upperFirst';

export enum DeprecateType {
  component = 'компонент',
  event = 'событие',
  prop = 'проп',
  slot = 'слот'
}

function createMessage(message: string, component?: any): string | void {
  if (component) {
    component.__loggedMessages = component.__loggedMessages || [];
    if (component.__loggedMessages.includes(message)) return;
    component.__loggedMessages.push(message);
  }
  const componentName = component?.name;
  const messagePrefix = componentName ? `[${componentName}] ` : '';
  return `${messagePrefix}${message}`;
}

export function consoleInfo(message: string, component?: any): void {
  const newMessage = createMessage(message, component);
  newMessage && console.info(newMessage);
}

export function consoleWarn(message: string, component?: any): void {
  const newMessage = createMessage(message, component);
  newMessage && console.warn(newMessage);
}

export function consoleError(message: string, component?: any): void {
  const newMessage = createMessage(message, component);
  newMessage && console.error(newMessage);
}

export function deprecate(
  original: string,
  todo: string,
  type?: DeprecateType,
  component?: any
) {
  todo = upperFirst(todo);
  todo = todo[todo.length - 1] === '.' ? todo : `${todo}.`;
  const markedWord = type === DeprecateType.event ? 'помечено' : 'помечен';

  consoleWarn(
    `${type ? `${type} ` : ''}${original} ${markedWord} устаревшим. ${todo}`,
    component
  );
}

export function deprecateProp(original: string, todo: string, component?: any) {
  return deprecate(original, todo, DeprecateType.prop, component);
}

export function deprecateEvent(
  original: string,
  todo: string,
  component?: any
) {
  return deprecate(original, todo, DeprecateType.event, component);
}

export function deprecateComponent(
  original: string,
  todo: string,
  component?: any
) {
  return deprecate(original, todo, DeprecateType.component, component);
}

export function deprecateSlot(original: string, todo: string, component?: any) {
  return deprecate(original, todo, DeprecateType.slot, component);
}
