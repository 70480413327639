import { Card, ContentParagraph, Button, Icon, Space, Tag, IconSize, TagSize, LogoBaseSimple } from '@hse-design/react';
import { useState } from 'react';
import { Playground, Props } from 'docz';
import { ComponentLinks, FigmaEmbed } from "../../../../src/common";
import * as React from 'react';
export default {
  Card,
  ContentParagraph,
  Button,
  Icon,
  Space,
  Tag,
  IconSize,
  TagSize,
  LogoBaseSimple,
  useState,
  Playground,
  Props,
  ComponentLinks,
  FigmaEmbed,
  React
};