import { useState } from 'react';
import { Icon, IconActionArrowBack, IconActionArrowForward, SegmentedControl, SegmentedControlItem, Size, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { FigmaEmbed, ComponentLinks } from "../../../../src/common";
import { colorBrand } from '@hse-design/tokens';
import * as React from 'react';
export default {
  useState,
  Icon,
  IconActionArrowBack,
  IconActionArrowForward,
  SegmentedControl,
  SegmentedControlItem,
  Size,
  Space,
  Playground,
  Props,
  FigmaEmbed,
  ComponentLinks,
  colorBrand,
  React
};