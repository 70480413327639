import { Playground } from 'docz';
import { Space, Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '@hse-design/react';
import { P, Link, ComponentLinks } from "../../../../src/common";
import * as React from 'react';
export default {
  Playground,
  Space,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  P,
  Link,
  ComponentLinks,
  React
};