import {
  applyChanges,
  filterNulls,
  flatDeco,
  MixinClass,
  setState,
  SetStateFn
} from '../../utils';
import {
  CalendarLevel,
  DayDate,
  ICalendarState,
  ICalendarStateProps,
  MonthDate,
  TargetField,
  YearDate
} from './types';

export class CalendarState implements MixinClass {
  static defaultMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  static initialState: ICalendarState = {
    level: CalendarLevel.day,
    selectedMonth: new Date().getMonth(),
    selectedYear: new Date().getFullYear(),
    selectedOptions: [],
    targetSelectedOptions: []
  };

  static defaultProps = {
    targetField: TargetField.first,
    yearFrom: new Date().getFullYear() - 10,
    yearTo: new Date().getFullYear() + 10,
    multiple: false,
    monthsLabels: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь'
    ],
    weekLabels: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
    daysNumber: 42
  };

  // props
  props: ICalendarStateProps = { ...CalendarState.defaultProps };

  // state
  state: ICalendarState = { ...CalendarState.initialState };
  setState: (changes: Partial<ICalendarState>) => void;

  constructor(
    protected readonly componentSetState: SetStateFn<ICalendarState>,
    protected readonly calendarProps?: Partial<ICalendarStateProps>
  ) {
    this.setState = flatDeco(componentSetState, setState);
    this.props = { ...this.props, ...filterNulls(calendarProps) };
  }

  /**
   * @param a
   * @param b
   */
  protected sortByDateTimeComparator = (a: Date, b: Date) =>
    a.getTime() - b.getTime();

  /**
   * Устанавливает выбранную дату в зависимости от правила выбора props.multiple
   * @param value
   * @param selectedOptions
   * @param filter
   */
  protected changeValue = (
    value: Date,
    selectedOptions: Date[] = [],
    filter = 'Invalid Date'
  ): void => {
    const filteredOptions = selectedOptions.filter(
      (v) => v.toString() !== filter
    );
    if (this.props.multiple && selectedOptions?.length > 0 && selectedOptions?.length < 2) {
      let newSelectedOptions: Date[] = [];
      if (filteredOptions?.[filteredOptions.length - 1])
        newSelectedOptions.push(filteredOptions?.[filteredOptions.length - 1]);
      newSelectedOptions.push(value);
      if (typeof this.props.value === 'undefined') {
        this.setState({
          targetSelectedOptions: newSelectedOptions.map((v) => v.toString()),
          selectedOptions: newSelectedOptions.map((v) => v.toString())
        });
      } else {
        this.props.onChange?.(
          newSelectedOptions
            .sort(this.sortByDateTimeComparator)
            .map((v) => v.toString())
        );
      }
    } else {
      if (typeof this.props.value === 'undefined') {
        this.setState({
          targetSelectedOptions: [value.toString()],
          selectedOptions: [value.toString()]
        });
      } else {
        this.props.onChange?.(
          [value].sort(this.sortByDateTimeComparator).map((v) => v.toString())
        );
      }
    }
  };

  /**
   * Нормализует значение Date без определения часов, минут, секунд, миллисекунд
   * @param day
   */
  protected subDayInfo = (day: Date): number =>
    new Date(day.getFullYear(), day.getMonth(), day.getDate()).getTime();

  /**
   * Создает массив годов в диапазоне props.yearFrom - props.yearTo
   */
  protected generateYears = (): number[] => {
    const years: number[] = [];
    for (let i = this.props.yearFrom; i <= this.props.yearTo; i++) years.push(i);
    return years;
  };

  /**
   * Устанавливает через this.setState модифицированное значение месяца
   * @param year
   * @param month
   * @param modify
   */
  protected onMonthModify = (year, month, modify) => {
    const current = new Date(year, month);
    current.setMonth(modify(current.getMonth()));
    while (
      this.isYearDisabled(current.getFullYear()) ||
      this.isMonthDisabled(current.getFullYear(), current.getMonth())
    )
      current.setMonth(modify(current.getMonth()));
    if (!this.inCalendarRange(current.toString())) return;
    this.setState({
      selectedMonth: current.getMonth(),
      selectedYear: current.getFullYear()
    });
  };

  protected inCalendarRange = (date: string): boolean =>
    this.isDayInSelectedRange(date, [
      new Date(this.props.yearFrom, 1, 1).toString(),
      new Date(this.props.yearTo, 13, 0).toString()
    ]);

  /**
   * Позволяет извне обновить параметры CalendarState слиянием this.props и props,
   * @param props
   */
  updateProps = (props: Partial<ICalendarStateProps>) => {
    this.setState({
      targetSelectedOptions: props.value,
      selectedOptions: props.value
    });
    applyChanges(this.props, props);
  };

  /**
   * Определяет принадлежность обрабатываемой даты к текущей выбранной дате - диапазону дат
   * @param day
   * @param selectedOptions
   */
  protected isDayInSelectedRange = (
    day: string,
    selectedOptions: string[] = []
  ) => {
    const selectedDate = new Date(day);
    const selectedDates = selectedOptions.map((v) => new Date(v));
    if (selectedOptions?.length === 1)
      return (
        this.subDayInfo(selectedDates[0]) === this.subDayInfo(selectedDate)
      );
    else if (selectedDates?.length > 1) {
      const sortedDates = selectedDates.sort(
        (a, b) => a.getTime() - b.getTime()
      );
      return (
        this.subDayInfo(selectedDate) >= this.subDayInfo(sortedDates[0]) &&
        this.subDayInfo(selectedDate) <= this.subDayInfo(sortedDates[1])
      );
    }
    return false;
  };
  /**
   * Определяет,принадлежит ли дата к набору отключенных дат
   * или отключенных годов
   * или отключенных месяцев
   * @param day
   */
  protected isDateDisabled = (day: Date) =>
    !!(
      this.isDayDisabled(day) ||
      this.isMonthDisabled(day.getFullYear(), day.getMonth()) ||
      this.isYearDisabled(day.getFullYear())
    );

  /**
   * Определяет принадлежность обрабатываемой даты к текущему месяцу,году
   * @param day
   * @param year
   * @param month
   */
  protected isDayInCurrentMonth = (day: Date, year: number, month: number) =>
    new Date(year, month, 0).getTime() < day.getTime() &&
    day.getTime() < new Date(year, month + 1, 1).getTime();
  /**
   * Определяет,принадлежит ли дата к набору отключенных дат
   * @param day
   */
  protected isDayDisabled = (day: Date) =>
    !!this.props.disabledDays?.some?.(
      (v) => this.subDayInfo(new Date(v)) === this.subDayInfo(day)
    );
  /**
   * Определяет,принадлежит ли месяц к набору отключенных месяцев
   * @param year
   * @param month
   */
  isMonthDisabled = (year, month) =>
    this.props.disabledMonths?.some?.((v) => {
      const [m, y] = String(v).split('-');
      if (!m || !y) return false;

      return (
        new Date(Number(y), Number(m) - 1, 1).getTime() ===
        new Date(year, month, 1).getTime()
      );
    });
  /**
   * Определяет, принадлежит ли год к набору отключенных годов
   * @param year
   */
  protected isYearDisabled = (year) =>
    this.props.disabledYears?.some?.((v) => v === year);

  /**
   * Смещает текущий отображаемый месяц на один назад
   * @param year
   * @param month
   */
  onPrevMonthSelect = (year, month) => {
    this.onMonthModify(year, month, (v) => v - 1);
  };

  /**
   * Смещает текущий отображаемый месяц на один вперед
   * @param year
   * @param month
   */
  onNextMonthSelect = (year, month) =>
    this.onMonthModify(year, month, (v) => v + 1);

  /**
   * Возвращает множество годов по параметрам  props.yearFrom - props.yearTo
   */
  getListOfYears = () => this.generateYears();

  /**
   * Возвращает множество названий дней недели
   */
  getWeekLabels = () => this.props.weekLabels;

  /**
   * Возвращает название месяца по номеру
   * @param month
   */
  getMonthLabel = (month: number) => this.props.monthsLabels?.[month];

  /**
   * Возвращает набор данных для отображения страницы CalendarLevel.month
   */
  getAvailableMonths = (year: number, selectedMonth: number): MonthDate[] => {
    const availableMonths: MonthDate[] = [];
    CalendarState.defaultMonths.forEach(
      (month) =>
        !this.isMonthDisabled(year, month) &&
        availableMonths.push({
          key: month,
          label: this.getMonthLabel(month),
          onClickEvent: () => this.onMonthSelect(month),
          selected: month === selectedMonth
        })
    );
    return availableMonths;
  };

  /**
   * Возвращает набор данных для отображения страницы CalendarLevel.
   * @param selectedYear
   */
  getAvailableYears = (selectedYear: number): YearDate[] => {
    const availableYears: YearDate[] = [];
    this.generateYears().forEach(
      (year) =>
        !this.isYearDisabled(year) &&
        availableYears.push({
          key: year,
          onClickEvent: () => this.onYearSelect(year),
          selected: year === selectedYear
        })
    );
    return availableYears;
  };

  /**
   * Event при выборе дня
   * @param date
   * @param selectedOptions
   */
  dayOnClick = (date, selectedOptions) => () =>
    !this.isDateDisabled(date) &&
    this.onDaySelect(date.toString(), selectedOptions);

  /**
   * Event при наведении на день
   * @param date
   * @param selectedOptions
   */
  dayOnMouseEnter = (date, selectedOptions) => () => {
    if (
      !(
        !this.isDateDisabled(date) &&
        !selectedOptions?.some?.((day) => day === date.toString()) &&
        selectedOptions?.length < 2
      )
    )
      return;
    else {
      if (this.props.targetField === TargetField.first) {
        this.setState({
          selectedOptions: selectedOptions?.length
            ? [selectedOptions[0]]
            : [],
          targetSelectedOptions: selectedOptions?.length
            ? [selectedOptions[0], date]
            : [date]
        });
      } else {
        this.setState({
          selectedOptions: selectedOptions?.length
            ? [selectedOptions[selectedOptions.length - 1]]
            : [],
          targetSelectedOptions: selectedOptions?.length
            ? [selectedOptions[selectedOptions.length - 1], date]
            : [date]
        });
      }
    }
  };

  /**
   * Возвращает набор дней для текущего отображаемого месяца
   * @param year
   * @param month
   * @param selectedOptions
   * @param targetSelectedOptions
   */
  getDaysByDate = (
    year: number,
    month: number,
    selectedOptions: string[],
    targetSelectedOptions: string[]
  ): DayDate[] => {
    const prevMonday = new Date(year, month, 1);
    prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
    const day = prevMonday;
    const daysList: DayDate[] = [];
    for (let i = 0; i < this.props.daysNumber; i++) {
      const dateClone = new Date(day);
      daysList.push({
        date: day.getDate(),
        onClick: this.dayOnClick(dateClone, selectedOptions),
        onMouseEnter: this.dayOnMouseEnter(dateClone, selectedOptions),
        disabled: this.isDateDisabled(dateClone),
        selected:
          !this.isDateDisabled(dateClone) &&
          selectedOptions?.some?.((day) => new Date(new Date(day).getFullYear(), new Date(day).getMonth(), new Date(day).getDate()).toString() === dateClone.toString()),
        selectable:
          !this.isDateDisabled(dateClone) &&
          !this.isDayInSelectedRange(dateClone.toString(), selectedOptions),
        difMonth:
          !this.isDayInCurrentMonth(dateClone, year, month) ||
          this.isDayDisabled(dateClone),
        inTargetRange:
          this.props.multiple &&
          !this.isDateDisabled(dateClone) &&
          this.isDayInSelectedRange(dateClone.toString(), targetSelectedOptions)
      });
      day.setDate(day.getDate() + 1);
    }
    for (let i = 0; i < this.props.daysNumber - 1; i++)
      daysList[i].fillNextGapSelectable =
        !(i % 7 === 6) &&
        daysList[i].inTargetRange &&
        daysList[i + 1].inTargetRange;

    return daysList;
  };

  /**
   * Обработчик выбора дня
   * @param day
   * @param selectedOptions
   */
  onDaySelect = (day: string, selectedOptions: string[]) => {
    const selectedDay = new Date(day);
    if (!this.inCalendarRange(day)) return;
    if (this.isDateDisabled(selectedDay)) return;
    this.onMonthSelect(selectedDay.getMonth());
    this.onYearSelect(selectedDay.getFullYear());
    this.changeValue(
      selectedDay,
      selectedOptions.map((v) => new Date(v))
    );
  };

  /**
   * Обработчик выбора месяца
   * @param month
   */
  onMonthSelect = (month: number) =>
    this.setState({ selectedMonth: month, level: CalendarLevel.day });

  /**
   * Обработчик выбора года
   * @param year
   */
  onYearSelect = (year: number) =>
    this.setState({ selectedYear: year, level: CalendarLevel.day });

  /**
   * Обработчик нажатия на вкладку months
   */
  onMonthLabelSelect = () => this.setState({ level: CalendarLevel.month });

  /**
   * Обработчик нажатия на вкладку years
   */
  onYearLabelSelect = () => this.setState({ level: CalendarLevel.year });

  onDidMount(): void {}

  onWillUnmount(): void {}

  static calendarDateToInputValue = (date: string) => {
    const value = new Date(date);
    const day = value.getDate();
    const month = value.getMonth();
    const year = value.getFullYear();
    return [day, month + 1, year].join('.');
  };

  static inputValueToCalendarDate = (value: string = '') => {
    const [day, month, year] = value?.split?.('.').map((v) => Number(v));
    return [day, month, year].some((v) => !v)
      ? value
      : new Date(year, month - 1, day).toString();
  };
}
