import { clamp, MixinClass, MixinClassEffect, SetStateFn } from '../../utils';
import { Key } from 'ts-key-enum';
import { WindowEventEffect } from '../../utils/WindowEventEffect';
import { applyChanges } from '../../utils/changes';

export namespace NRangeSlider {
  export enum TrackFillType {
    between = 'between',
    start = 'start',
    end = 'end',
    none = 'none'
  }

  export interface Value {
    id: string;
    value: number;
    label?: string;
    disabled?: boolean;
  }

  export interface ControlValue extends Value {
    position: number;
  }

  export interface TrackFill {
    from: number;
    to: number;
  }

  export interface DragHandleInfo {
    id: string;
    identifier: number;
    offset: number;
  }
}

/**
 * @deprecated use NRangeSlider.TrackFillType
 */
export import TrackFillType = NRangeSlider.TrackFillType;

/**
 * @deprecated use NRangeSlider.Value
 */
export import Value = NRangeSlider.Value;

/**
 * @deprecated use NRangeSlider.ControlValue;
 */
export import ControlValue = NRangeSlider.ControlValue;

/**
 * @deprecated use NRangeSlider.TrackFill;
 */
export import TrackFill = NRangeSlider.TrackFill;

/**
 * @deprecated use NRangeSlider.DragHandleInfo;
 */
export import DragHandleInfo = NRangeSlider.DragHandleInfo;

export interface IRangeSliderState {
  values: ControlValue[];
  fills: TrackFill[];
  focused?: boolean;
  selectedHandleId: string;
  dragHandleIds: DragHandleInfo[];
}

export interface IRangeSliderStateProps {
  min: number;
  max: number;
  step: number;
  values: Value[];
  fills: TrackFill[] | TrackFillType;
  onChange?: (values: Value[]) => any;
}

export interface IRangeSliderRefs {
  rootRef?: HTMLDivElement;
  controlsRef?: HTMLDivElement;
}

function isInnerValuesEqual(
  oldValues: Value[],
  newValues: Value[]
) {
  if (oldValues.length !== newValues.length) return false;
  return oldValues.every((oldValue, idx) => {
    const newValue = newValues[idx];
    return (
      newValue.id === oldValue.id &&
      oldValue.value === newValue.value &&
      oldValue.disabled === newValue.disabled &&
      oldValue.label === newValue.label
    );
  });
}

function innerValuesToValues(
  innerValues: ControlValue[]
): Value[] {
  return innerValues.map(({ id, label, value, disabled }) => ({
    id,
    label,
    value,
    disabled
  }));
}

export class RangeSliderState implements MixinClass {
  /**
   * @see https://github.com/Microsoft/TypeScript/issues/3841#issuecomment-337560146
   */
  ['constructor']: typeof RangeSliderState;

  // static defaults
  static initialState: IRangeSliderState = {
    values: [],
    fills: [],
    focused: false,
    selectedHandleId: '',
    dragHandleIds: []
  };

  static defaultProps: IRangeSliderStateProps = {
    values: [],
    fills: [],
    min: 0,
    max: 100,
    step: 1
  };

  // props
  props: IRangeSliderStateProps = { ...RangeSliderState.defaultProps };
  refs: IRangeSliderRefs = {};

  // state
  state: IRangeSliderState = { ...RangeSliderState.initialState };
  setState: (changes: Partial<IRangeSliderState>) => void;

  // internal
  private trackRadius = 0;

  // lifecycle
  constructor(setState: SetStateFn<IRangeSliderState>) {
    this.setState = (changes: Partial<IRangeSliderState>) => {
      setState((prevState: IRangeSliderState) => ({
        ...prevState,
        ...changes
      }));
    };
  }

  public update(
    state: Partial<IRangeSliderState>,
    props: Partial<IRangeSliderStateProps>,
    refs: Partial<IRangeSliderRefs>
  ) {
    applyChanges(this.state, state);
    applyChanges(this.props, props);
    applyChanges(this.refs, refs);

    if (this.refs.rootRef && this.refs.controlsRef) {
      const trackX = this.refs.rootRef.getBoundingClientRect().x;
      const trackEffectiveX = this.refs.controlsRef.getBoundingClientRect().x;
      this.trackRadius = trackEffectiveX - trackX;
    }

    this.rootListenersEffect.update(this.refs.rootRef);
    this.setValuesEffect.update(
      this.props.values,
      this.props.fills,
      this.props.min,
      this.props.max,
      this.refs.rootRef
    );
  }

  public onDidMount = () => {};

  public onWillUnmount = () => {
    this.rootListenersEffect.destroy();
    this.mouseMoveEffect.destroy();
    this.mouseUpEffect.destroy();
    this.windowKeyDownEffect.destroy();
    this.touchMoveEffect.destroy();
    this.touchEndEffect.destroy();
  };

  // rest
  private getTrackWidth() {
    if (!this.refs.controlsRef) return 0;
    const { width } = this.refs.controlsRef.getBoundingClientRect();
    return width;
  }

  private valueToPosition = (value: number) => {
    if (!this.refs.rootRef) return 0;
    const { min, max } = this.props;
    const correctedWidth = this.getTrackWidth();
    const correctedValue = clamp(value, min, max);
    return ((correctedValue - min) / (max - min)) * correctedWidth;
  };

  private positionToValue = (position: number) => {
    if (!this.refs.controlsRef) return this.props.min;
    const width = this.getTrackWidth();
    const { min, max, step } = this.props;

    const projectedValue = (position * (max - min)) / width + min;
    const value = Math.floor((projectedValue + step / 2) / step) * step;
    return clamp(value, min, max);
  };

  private changeValues(
    newValues: ControlValue[],
    changes?: Partial<IRangeSliderState>
  ) {
    const withOnChange = !isInnerValuesEqual(this.state.values, newValues);
    if (changes) {
      this.setState(changes);
    }
    if (this.props.onChange && withOnChange) {
      const newValuesProp = innerValuesToValues(newValues);
      this.props.onChange(newValuesProp);
    }
  }

  private changeHandleValue(
    id: string,
    newValue: number | ((v: number) => number)
  ) {
    const { min, max } = this.props;
    const newValues = this.state.values.map((value) => {
      if (value.id === id) {
        const changedValue =
          typeof newValue === 'function' ? newValue(value.value) : newValue;
        const clampedValue = clamp(changedValue, min, max);
        return {
          ...value,
          value: clampedValue,
          position: this.valueToPosition(clampedValue)
        };
      }
      return value;
    });
    this.changeValues(newValues);
  }

  private getNextHandle(dir: number = 1) {
    const sortedInnerValues = this.getSortedValues();

    const iCurrentHandle = this.state.selectedHandleId
      ? sortedInnerValues.findIndex(
          (value) => this.state.selectedHandleId === value.id
        )
      : -1;

    let i = iCurrentHandle + dir;
    while (sortedInnerValues[i] && sortedInnerValues[i].disabled) {
      i += dir;
    }

    if (sortedInnerValues[i]) {
      return sortedInnerValues[i];
    }
    return null;
  }

  private getSortedValues() {
    return this.state.values.slice().sort((a, b) => a.position - b.position);
  }

  private getTargetControl(target: HTMLElement) {
    const handleRoot = target.closest('.hse-RangeSlider__control');
    const id = handleRoot?.getAttribute('data-id');
    if (!id) return null;
    const activeValue = this.state.values.find(
      (value) => value.id === id && !value.disabled
    );
    if (!activeValue) return null;
    return activeValue;
  }

  private onMouseDown = (event: MouseEvent) => {
    const activeValue = this.getTargetControl(event.target as HTMLElement);
    if (!activeValue) return;
    this.setState({
      dragHandleIds: [
        {
          id: activeValue.id,
          identifier: 0,
          offset: event.clientX - activeValue.position
        }
      ]
    });
    this.mouseMoveEffect.update(true);
    this.mouseUpEffect.update(true);
  };

  private onMouseUp = () => {
    const newValues = this.state.values.map((value) => ({
      ...value,
      position: this.valueToPosition(value.value)
    }));
    this.changeValues(newValues, { dragHandleIds: [] });
    this.mouseMoveEffect.update(false);
    this.mouseUpEffect.update(false);
  };

  private onMouseMove = (event: MouseEvent) => {
    const currentClientX = event.clientX;
    const innerValues = this.state.values;
    const dragHandle = this.state.dragHandleIds?.[0];
    if (!dragHandle) return;
    const trackWidth = this.getTrackWidth();
    const newInnerValues = innerValues.map((value) => {
      if (value.id === dragHandle.id) {
        const newPosition = currentClientX - dragHandle.offset;
        const computedPos = clamp(newPosition, 0, trackWidth);
        const newValue = this.positionToValue(newPosition);
        return {
          ...value,
          value: newValue,
          position: computedPos
        };
      }
      return value;
    });
    this.changeValues(newInnerValues);
  };

  private onTouchStart = (event: TouchEvent) => {
    const activeValue = this.getTargetControl(event.target as HTMLElement);
    if (!activeValue) return;

    if (event.changedTouches.length === 0) return;

    event.preventDefault();
    const touch = event.changedTouches[0];
    this.setState({
      dragHandleIds: [
        ...this.state.dragHandleIds,
        {
          id: activeValue.id,
          identifier: touch.identifier,
          offset: touch.clientX - activeValue.position
        }
      ]
    });

    this.touchMoveEffect.update(true);
    this.touchEndEffect.update(true);
  };

  private onTouchEnd = (event: TouchEvent) => {
    const removedIds: string[] = [];

    const newDragHandles = this.state.dragHandleIds.filter((d) => {
      for (let i = 0; i < event.changedTouches.length; i++) {
        if (event.changedTouches[i].identifier === d.identifier) {
          removedIds.push(d.id);
          return false;
        }
      }
      return true;
    });

    const newValues = this.state.values.map((value) => {
      if (removedIds.includes(value.id)) {
        return {
          ...value,
          position: this.valueToPosition(value.value)
        };
      }
      return value;
    });

    this.changeValues(newValues, { dragHandleIds: newDragHandles });

    if (event.touches.length === 0) {
      this.touchMoveEffect.update(false);
      this.touchEndEffect.update(false);
    }
  };

  private onTouchMove = (event: TouchEvent) => {
    const getTouch = (identifier: number) => {
      for (let i = 0; i < event.touches.length; i++) {
        if (event.touches[i].identifier === identifier) {
          return event.touches[i];
        }
      }
    };

    const trackWidth = this.getTrackWidth();
    const newInnerValues = this.state.values.map((value) => {
      const dragHandle = this.state.dragHandleIds.find(
        (d) => d.id === value.id
      );
      if (dragHandle) {
        const touch = getTouch(dragHandle.identifier);
        if (touch) {
          const newPosition = touch.clientX - dragHandle.offset;
          const computedPos = clamp(newPosition, 0, trackWidth);
          const newValue = this.positionToValue(newPosition);
          return {
            ...value,
            value: newValue,
            position: computedPos
          };
        }
      }
      return value;
    });
    event.preventDefault();
    this.changeValues(newInnerValues);
  };

  private onFocus = () => {
    this.setState({ focused: true });
    this.windowKeyDownEffect.update(true);
  };

  private onBlur = () => {
    this.setState({ focused: false, selectedHandleId: '' });
    this.windowKeyDownEffect.update(false);
  };

  private onKeyDown = (e: KeyboardEvent) => {
    if (!this.state.focused) {
      return;
    }

    if (e.key === Key.Tab) {
      const nextHandle = this.getNextHandle(e.shiftKey ? -1 : 1);

      if (nextHandle) {
        const newHandleId = nextHandle.id.toString();
        this.setState({ selectedHandleId: newHandleId });
        e.preventDefault();
      }
    }

    if (this.state.selectedHandleId) {
      if (e.key === Key.ArrowRight) {
        e.preventDefault();
        const { step } = this.props;
        this.changeHandleValue(this.state.selectedHandleId, (v) => v + step);
      } else if (e.key === Key.ArrowLeft) {
        e.preventDefault();
        const { step } = this.props;
        this.changeHandleValue(this.state.selectedHandleId, (v) => v - step);
      }
    }
  };

  // effects
  private rootListenersEffect = new MixinClassEffect(
    (rootRef?: HTMLDivElement) => {
      if (rootRef) {
        rootRef.addEventListener('mousedown', this.onMouseDown);
        rootRef.addEventListener('touchstart', this.onTouchStart);
        rootRef.addEventListener('focus', this.onFocus);
        rootRef.addEventListener('blur', this.onBlur);
      }

      return () => {
        if (!rootRef) return;
        rootRef.removeEventListener('mousedown', this.onMouseDown);
        rootRef.removeEventListener('focus', this.onFocus);
        rootRef.addEventListener('blur', this.onBlur);
      };
    }
  );

  private mouseUpEffect = new WindowEventEffect('mouseup', this.onMouseUp);
  private mouseMoveEffect = new WindowEventEffect(
    'mousemove',
    this.onMouseMove
  );

  private touchEndEffect = new WindowEventEffect('touchend', this.onTouchEnd);
  private touchMoveEffect = new WindowEventEffect(
    'touchmove',
    this.onTouchMove
  );

  private windowKeyDownEffect = new WindowEventEffect(
    'keydown',
    this.onKeyDown
  );

  private setValuesEffect = new MixinClassEffect(
    (
      values: Value[],
      fills: TrackFill[] | TrackFillType | undefined,
      min: number,
      max: number,
      rootRef: HTMLDivElement | undefined
    ) => {
      // Проецируем значения
      const newValues = values.map(({ id, label, value, disabled }) => ({
        label,
        value,
        position: this.valueToPosition(value),
        id: id.toString(),
        disabled
      }));

      const valueValues = newValues.map((v) => v.value);

      // Применяем значения по-умолчанию в зависимости от количества ползунков
      const fillsAdj =
        fills ||
        (valueValues.length === 2
          ? TrackFillType.between
          : valueValues.length === 1
          ? TrackFillType.start
          : undefined);

      // Конвертируем значения в массивы отрезков
      const fillsProp =
        fillsAdj === TrackFillType.start
          ? [{ from: min, to: Math.min(...valueValues) }]
          : fillsAdj === TrackFillType.end
          ? [{ from: Math.max(...valueValues), to: max }]
          : fillsAdj === TrackFillType.between
          ? [{ from: Math.min(...valueValues), to: Math.max(...valueValues) }]
          : typeof fillsAdj === 'string'
          ? []
          : fillsAdj;

      // Проецируем отрезки
      const newFills =
        fillsProp?.map((fill) => {
          const x1 = this.valueToPosition(fill.from);
          const x2 = this.valueToPosition(fill.to);
          const xmin = Math.min(x1, x2);
          const xmax = Math.max(x1, x2);
          return {
            ...fill,
            from: xmin - this.trackRadius,
            to: xmax + this.trackRadius
          };
        }) || [];

      this.setState({
        values: newValues,
        fills: newFills
      });
    }
  );
}
