import { ComponentLinks, FigmaEmbed } from "../../../../src/common";
import { Button, ImageCropper, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { useState } from 'react';
import * as React from 'react';
export default {
  ComponentLinks,
  FigmaEmbed,
  Button,
  ImageCropper,
  Space,
  Playground,
  Props,
  useState,
  React
};