import { FileInputProgress } from './FileInput';

export interface MultiFileInputFile {
  id: string | number;
  fileName: string;
  size?: string;
  url?: string;
  type?: string;
  progress?: FileInputProgress;
}

export enum MultiFileInputSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export function formatBytes(bytes = 0, decimals = 2, sizeNameDictionary = ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ', 'ЭБ', 'ЗБ', 'ЙБ']) {
  if (bytes === 0) return '0 Б';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizeNameDictionary?.[i];
}
