import { FileInput } from '@hse-design/react';
import { ComponentLinks, FigmaEmbed } from "../../../../src/common";
import { Playground, Props } from 'docz';
import { useState, useEffect, useRef } from 'react';
import * as React from 'react';
export default {
  FileInput,
  ComponentLinks,
  FigmaEmbed,
  Playground,
  Props,
  useState,
  useEffect,
  useRef,
  React
};