export function collectionToArray(collection: HTMLCollection) {
  return Array.prototype.slice.call(collection);
}

export function isPrintableKeyDown(e: KeyboardEvent) {
  return e.key.length === 1;
}

export function eventTargetIsElement(e: EventTarget): e is Element {
  return e instanceof Element;
}
