import { P, SizingDemo, SpacingDemo, OpacityDemo, RadiusDemo, ShadowDemo, BreakpointDemo } from "../../../../src/common";
import * as React from 'react';
export default {
  P,
  SizingDemo,
  SpacingDemo,
  OpacityDemo,
  RadiusDemo,
  ShadowDemo,
  BreakpointDemo,
  React
};