import Color from 'color';
import {
  colorElementOverlayDark,
  colorElementOverlayLight
} from '@hse-design/tokens';

const cssVarPrefix = '--clickable-icon-color-';

const stateOverlayColors = {
  disabled: Color(colorElementOverlayLight).alpha(0.6),
  hover: Color(colorElementOverlayDark).alpha(0.4)
};

export function getClickableIconColorStyles(color: string | undefined) {
  if (!color) {
    return {};
  }

  try {
    const baseColor = Color(color);
    const styles: Record<string, string> = {
      [cssVarPrefix + 'default']: color
    };
    for (const state of Object.keys(stateOverlayColors)) {
      const overlayColor = Color(stateOverlayColors[state]);
      styles[cssVarPrefix + state] = baseColor.mix(overlayColor).string();
    }

    return styles;
  } catch (e) {
    // здесь не может быть серьезных ошибок, поэтому просто игнорируем их
    return {};
  }
}
