import { Collapse, ContentCollapse } from '@hse-design/react';
import { colorBrandDark, spacingSmall } from '@hse-design/tokens';
import { Playground, Props } from 'docz';
import { useState } from 'react';
import { ComponentLinks, FigmaEmbed } from "../../../../src/common";
import * as React from 'react';
export default {
  Collapse,
  ContentCollapse,
  colorBrandDark,
  spacingSmall,
  Playground,
  Props,
  useState,
  ComponentLinks,
  FigmaEmbed,
  React
};