import { useState } from 'react';
import { Button, Space, Size, IconActionLinkOpen, IconActionPlus } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks, FigmaEmbed, Polymorphic } from "../../../../src/common";
import { colorSystemSuccess, colorSystemWarning, colorSystemError } from '@hse-design/tokens';
import * as React from 'react';
export default {
  useState,
  Button,
  Space,
  Size,
  IconActionLinkOpen,
  IconActionPlus,
  Playground,
  Props,
  ComponentLinks,
  FigmaEmbed,
  Polymorphic,
  colorSystemSuccess,
  colorSystemWarning,
  colorSystemError,
  React
};