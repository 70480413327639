import { Space, Size } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { P, Link, ComponentLinks } from "../../../../src/common";
import { colorBrand3 } from '@hse-design/tokens';
import * as React from 'react';
export default {
  Space,
  Size,
  Playground,
  Props,
  P,
  Link,
  ComponentLinks,
  colorBrand3,
  React
};