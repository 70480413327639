import { IOption, IOptionGroup } from './types';

/**
 * Проверяет, что объект data удовлетворяет интерфейсу IOption
 */
export function isIOption<Option extends IOption = IOption>(
  data: any
): data is Option {
  if (typeof data !== 'object') return false;
  const { label, value, inactive, divided, group } = data;
  return (
    typeof label === 'string' &&
    (typeof value === 'string' || typeof value === 'number') &&
    (inactive === undefined || typeof inactive === 'boolean') &&
    (divided === undefined || typeof divided === 'boolean') &&
    (group === undefined || typeof group === 'string')
  );
}

/**
 * Проверяет, что объект data удовлетворяет интерфейсу IOptionGroup
 */
export function isIOptionGroup<Option extends IOption = IOption>(
  data: any
): data is IOptionGroup<Option> {
  if (typeof data !== 'object') return false;
  return !!data.options;
}

/**
 * Группирует опции по группам.
 * Группы размещаются в порядке появления в массиве опций.
 * Опции без группы остаются в начале итогового массива.
 * @param filteredOptions - массив опций с указанием групп
 */
export function groupOptions<Option extends IOption = IOption>(
  filteredOptions: Option[]
): (Option | IOptionGroup<Option>)[] {
  if (!filteredOptions.some((option) => !!option.group)) {
    return filteredOptions;
  }

  const groupsMap = {};
  for (const option of filteredOptions) {
    if (option.group) {
      if (groupsMap[option.group]) {
        groupsMap[option.group].push(option);
      } else {
        groupsMap[option.group] = [option];
      }
    }
  }

  const nonGroupOptions: (
    | IOptionGroup<Option>
    | Option
  )[] = filteredOptions.filter((opt) => !opt.group);

  const groups = Object.keys(groupsMap).map(
    (key) =>
      ({
        label: key,
        options: groupsMap[key]
      } as IOptionGroup<Option>)
  );

  return [...nonGroupOptions, ...groups];
}

/**
 * Преобразует массив групп опций в массив последовательных опций.
 * @param options - массив групп опций
 */
export function flattenOptions<Option extends IOption = IOption>(
  options: (Option | IOptionGroup<Option>)[]
): Option[] {
  const flatOptions: Option[] = [];
  for (const item of options) {
    if (isIOptionGroup(item)) {
      const optionGroup = item;
      for (const option of optionGroup.options) {
        flatOptions.push({
          ...option,
          group: optionGroup.label
        });
      }
    } else {
      flatOptions.push(item);
    }
  }
  return flatOptions;
}

/**
 * Возвращает объекты опций на основе их значений
 * @param value - значение или массив значений
 * @param options - массив опций
 * @param multiple - не используется
 */
export function getValueOptions<Option extends IOption = IOption>(
  value: null | Option['value'] | Option | (Option['value'] | Option)[],
  options: Option[],
  multiple: boolean | undefined
) {
  if (Array.isArray(value)) {
    return value.map((value) => {
      return options.find((opt) => matchOption(value, opt));
    });
  } else if (value) {
    return [options.find((opt) => matchOption(value, opt))];
  } else {
    return [];
  }
}

/**
 * Создает простую опцию, значение которой совпадает с названием
 * @param value - значение
 */
export function createSimpleOption<Option extends IOption = IOption>(
  value: Option['value']
): Option {
  return { value, label: String(value) } as any;
}

/**
 * Проверяет соответствие опции её значению
 * @param value - значение
 * @param option - опция
 */
export function matchOption<Option extends IOption = IOption>(
  value: Option['value'] | Option | null,
  option: Option
) {
  return value
    ? typeof value === 'object'
      ? value.value === option.value
      : value === option.value
    : false;
}

/**
 * Находит опцию в массиве опций
 * @param value - опция
 * @param options - массив опций
 */
export function findOrCreateOption<Option extends IOption = IOption>(
  value: Option | Option['value'] | null,
  options: Option[]
) {
  return value === null || value === undefined
    ? null
    : typeof value === 'object'
    ? value
    : options?.find((o) => value === o.value) || createSimpleOption(value);
}
