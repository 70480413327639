import { useMemo, useState } from 'react';
import { IconStatusLikeFilled, IconStatusStarFilled, Select, SelectVariant, SelectWidthVariant, Size, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks, FigmaEmbed } from "../../../../src/common";
import * as React from 'react';
export default {
  useMemo,
  useState,
  IconStatusLikeFilled,
  IconStatusStarFilled,
  Select,
  SelectVariant,
  SelectWidthVariant,
  Size,
  Space,
  Playground,
  Props,
  ComponentLinks,
  FigmaEmbed,
  React
};