import { ComponentLinks } from "../../../../src/common";
import imageCatUrl from '@hse-design/core/lib/assets/images/cat.svg?file';
import imageCommasUrl from '@hse-design/core/lib/assets/images/commas.svg?file';
import imageDoneUrl from '@hse-design/core/lib/assets/images/done.svg?file';
import imageUfoUrl from '@hse-design/core/lib/assets/images/ufo.svg?file';
import imageCrowUrl from '@hse-design/core/lib/assets/images/crow.svg?file';
import imageFailUrl from '@hse-design/core/lib/assets/images/fail.svg?file';
import imageLinkUrl from '@hse-design/core/lib/assets/images/link.svg?file';
import imageSearchUrl from '@hse-design/core/lib/assets/images/search.svg?file';
import * as React from 'react';
export default {
  ComponentLinks,
  imageCatUrl,
  imageCommasUrl,
  imageDoneUrl,
  imageUfoUrl,
  imageCrowUrl,
  imageFailUrl,
  imageLinkUrl,
  imageSearchUrl,
  React
};