import { ProgressBar, Space, Size } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { P, Link, ComponentLinks } from "../../../../src/common";
import * as React from 'react';
export default {
  ProgressBar,
  Space,
  Size,
  Playground,
  Props,
  P,
  Link,
  ComponentLinks,
  React
};