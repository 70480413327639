import { Popper, Switcher } from '@hse-design/react';
import { useState, useRef, useEffect } from 'react';
import { Playground, Props } from 'docz';
import * as React from 'react';
export default {
  Popper,
  Switcher,
  useState,
  useRef,
  useEffect,
  Playground,
  Props,
  React
};