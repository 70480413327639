import { MixinClass } from '../../utils/MixinClass';

interface IScrollTableState {
  fixedHeader?: boolean;
}

type Dispatch<A> = (value: Partial<A>) => void;
type SetStateAction<S> = S | ((prevState: S) => S);
export type ScrollTableSetStateFn = Dispatch<SetStateAction<IScrollTableState>>;

const isBrowser = () => typeof window !== 'undefined';

export class ScrollTable implements MixinClass {
  static initialState: IScrollTableState = {
    fixedHeader: false
  };
  tableBox?: DOMRect;
  table?: HTMLTableElement;
  top = 0;

  setState: ScrollTableSetStateFn;
  constructor(setState) {
    this.setState = (changes: Partial<IScrollTableState> = {}) => {
      setState((prevState: IScrollTableState = {}) => {
        return {
          ...prevState,
          ...changes
        };
      });
    };
    this.handleResize();
  }

  getHeaderRef() {
    return this.table?.querySelector('.hse-Table__thead');
  }

  getBodyRef() {
    return this.table?.querySelector('.hse-Table__tbody');
  }

  getFooterRef() {
    return this.table?.querySelector('.hse-Table__tfoot');
  }

  handleResize = () => {
    if (!isBrowser()) return;

    const headerRef = this.getHeaderRef();
    const footerRef = this.getFooterRef();
    const bodyRef = this.getBodyRef();

    const headerHeight = headerRef
      ? headerRef.getBoundingClientRect().height
      : 0;
    const footerHeight = footerRef
      ? footerRef.getBoundingClientRect().height
      : 0;
    const maxTableHeight = window.outerHeight;
    const maxBodyHeight = maxTableHeight - headerHeight - footerHeight;

    this.tableBox = this.table?.getBoundingClientRect?.();
    const heightOfViewport = window.outerHeight;

    if (this.tableBox && this.tableBox.height > heightOfViewport) {
      if (bodyRef) {
        (bodyRef as HTMLElement).style.maxHeight = `${maxBodyHeight}px`;
      }
      this.setState({
        fixedHeader: true,
        maxHeight: heightOfViewport
      });
    } else {
      this.setState({ fixedHeader: false });
      if (bodyRef) {
        (bodyRef as HTMLElement).style.maxHeight = ``;
      }
    }
  }

  update(table: HTMLTableElement) {
    this.table = table;
    this.tableBox = table.getBoundingClientRect();
  }

  onDidMount() {
    if (isBrowser()) {
      window.addEventListener('resize', this.handleResize);
    }
  }

  onWillUnmount() {
    if (isBrowser()) {
      window.removeEventListener('resize', this.handleResize);
    }
  }
}
