export interface DateProps {
  minuteFrom: string;
  minuteTo: string;
  disabledValues?: string[];
}

export class CustomDate {
  /**
   * Состояние итерации CustomDate,позволяет определить состояние псевдо-генератора
   */
  done: boolean;
  /**
   * Текущее итерируемое значение в минутах с 00:00, ограниченно:
   * снизу: 0,
   * сверху: 1440 (24 * 60)
   * @private
   */
  private valueMinutes: number;
  /**
   * Нижняя граница диапазона в минутах
   * @private
   */
  private readonly from: number;
  /**
   * Верхняя граница диапазона в минутах
   * @private
   */
  private readonly to: number;
  /**
   * Набор недоступных и отсутствующих дат
   * @private
   */
  private disabledValues?: string[];

  constructor(protected readonly dateProps: DateProps) {
    this.done = false;
    this.from = this.fromString(dateProps.minuteFrom);
    this.to = this.fromString(dateProps.minuteTo);
    this.disabledValues = dateProps.disabledValues;
    this.valueMinutes = this.fromString(dateProps.minuteFrom);
  }

  /**
   * Вспомогательный метод для приведения даты в формате HH:MM в число минут, в отсчете с 00:00
   * @param date
   * @protected
   */
  protected fromString(date: string): number {
    const [hours, minutes] = date.split(':');
    const minutesNumber = Number(hours) * 60 + Number(minutes);
    if (minutesNumber < 0) return 0;
    if (minutesNumber > 1440) return 1440;
    return minutesNumber;
  }

  /**
   * Вспомогательный метод для приведения минут в отсчете с 00:00, в дату формата HH:MM
   * @param date
   * @protected
   */
  protected minutesToString = (date: number): string =>
    [`${(date - (date % 60)) / 60}`.padStart(2, '0'),
      `${date % 60}`.padStart(2,'0')
    ].join(':');

  /**
   * Внешне доступный метод,позволяющий сдвинуть текущее значение valueMinutes на значение шага,
   * пропускает значения набора disabledValues
   * @param step
   */
  shiftValue(step: number): void {
    this.valueMinutes += step;
    if (this.disabledValues?.some((v) => v === this.getValue())) {
      this.shiftValue(step);
    }
  }

  /**
   * Внешне доступный метод,позволяющий получить значение CustomDate
   */
  getValue = (): string | null => {
    if (this.valueMinutes! < this.from) this.done = true;
    if (this.valueMinutes! > this.to) this.done = true;
    return this.valueMinutes && !this.done
      ? this.minutesToString(this.valueMinutes)
      : null;
  };

  /**
   * Внешне доступный метод,позволяющий создать диапазон значений для TimePicker
   * @param step
   */
  generateRange = (step: number) => {
    const rangeList: string[] = [];
    rangeList.push(this.minutesToString(this.from));
    while (!this.done) {
      this.shiftValue(step);
      const value = this.getValue();
      if (value) rangeList.push(value);
    }
    rangeList.push(this.minutesToString(this.to));
    return Array.from(new Set(rangeList));
  };
}
