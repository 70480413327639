import { shallowEqualArrays } from 'shallow-equal';

export type MixinClassEffectCleanupFn = () => void;
export type MixinClassEffectFn<Args extends unknown[]> = (
  ...args: Args
) => MixinClassEffectCleanupFn | void;

export class MixinClassEffect<Args extends unknown[]> {
  protected cleanup?: MixinClassEffectCleanupFn;
  public oldArgs?: Args;

  constructor(protected effectFn: MixinClassEffectFn<Args>) {}

  destroy() {
    this.cleanup?.();
  }

  update(...args: Args) {
    if (!shallowEqualArrays(this.oldArgs, args)) {
      this.cleanup?.();
      this.oldArgs = args;
      this.cleanup = this.effectFn(...args) || undefined;
    }
  }
}
