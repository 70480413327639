import { collectionToArray } from '../../utils/dom';
import { Optional } from '../../utils/types';

export function computeVisibleTags(
  containerRef: Optional<HTMLDivElement>,
  gap: number = 11
) {
  if (!containerRef) return Infinity;
  const { right: rootRight } = containerRef.getBoundingClientRect();
  const children = collectionToArray(containerRef.children);
  let count = 0;
  for (; count < children.length; count++) {
    const child = children[count];
    const { right } = child.getBoundingClientRect();
    if (rootRight === right) {
      count = 1;
      break;
    }
    const isLast = count === children.length - 1;
    const shift = isLast ? 0 : gap;
    if (rootRight - shift < right) {
      break;
    }
  }
  return count;
}
