import { useState, useMemo } from 'react';
import { Button, Select, Size, Space, Tooltip } from '@hse-design/react';
import { ComponentLinks, FigmaEmbed } from "../../../../src/common";
import { Playground, Props } from 'docz';
import * as React from 'react';
export default {
  useState,
  useMemo,
  Button,
  Select,
  Size,
  Space,
  Tooltip,
  ComponentLinks,
  FigmaEmbed,
  Playground,
  Props,
  React
};