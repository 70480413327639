import { useState } from 'react';
import { Playground, Props } from 'docz';
import { Alert, AlertLink, IconStatusInfo, Switcher, AlertContainer } from '@hse-design/react';
import { ComponentLinks, FigmaEmbed } from "../../../../src/common";
import * as React from 'react';
export default {
  useState,
  Playground,
  Props,
  Alert,
  AlertLink,
  IconStatusInfo,
  Switcher,
  AlertContainer,
  ComponentLinks,
  FigmaEmbed,
  React
};