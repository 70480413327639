import { Table, TableHead, TableBody, TableFooter, TableRow, TableCell, TableHeadCell } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { P, Link, ComponentLinks, FigmaEmbed } from "../../../../src/common";
import * as React from 'react';
export default {
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TableHeadCell,
  Playground,
  Props,
  P,
  Link,
  ComponentLinks,
  FigmaEmbed,
  React
};