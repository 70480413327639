import * as mime from 'mime/lite';

export const getFileNameWithoutExtension = (fileName: string = ''): string => {
  if (!fileName) return '';
  const extension = getFileExtension(fileName);
  return extension ? fileName.slice(0, -extension.length - 1) : fileName;
};

export const getFileExtension = (fileName: string = ''): string => {
  const parts = fileName?.split('.');
  if (parts.length > 1) {
    return parts.pop() || '';
  }
  return '';
};

export const splitFileName = (fileName: string = ''): [string, string] => {
  if (!fileName) return ['', ''];
  const extension = getFileExtension(fileName);
  const baseName = extension
    ? fileName.slice(0, -extension.length - 1)
    : fileName;
  return [baseName, extension];
};

export const getMimeTypeByFileName = (fileName: string = '') => {
  let mimeType: string | null = null;

  try {
    mimeType = mime.getType(fileName) || null;
  } catch {}

  return mimeType;
};
