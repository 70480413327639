import { useCallback, useEffect, useState } from 'react';
import { Button, Space, Spinner, Size } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { Link, P, ComponentLinks, FigmaEmbed } from "../../../../src/common";
import * as React from 'react';
export default {
  useCallback,
  useEffect,
  useState,
  Button,
  Space,
  Spinner,
  Size,
  Playground,
  Props,
  Link,
  P,
  ComponentLinks,
  FigmaEmbed,
  React
};