import { ComponentLinks, FigmaEmbed } from "../../../../src/common";
import { UserMenu, UserMenuList, UserMenuListItem, IconActionLogout, IconGlobalSettings } from '@hse-design/react';
import { Playground, Props } from 'docz';
import * as React from 'react';
export default {
  ComponentLinks,
  FigmaEmbed,
  UserMenu,
  UserMenuList,
  UserMenuListItem,
  IconActionLogout,
  IconGlobalSettings,
  Playground,
  Props,
  React
};