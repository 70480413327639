import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@hse-design/react';
import { Link } from './Link';

interface ComponentLinksProps {
  /**
   * Название компонента
   */
  name?: string;
  /**
   * Ссылка на макет компонента в Figma
   */
  figma?: string;
  /**
   * Сторибук компонента. Только квери параметр
   * @example ?path=/<...>
   */
  storybook?: string;
  /**
   * Ссылка на документацию компонента во вью. Только URL path
   * @example /components/<...>
   */
  vue?: string;
  /**
   * Vue сторибук компонента. Только квери параметр
   * @example ?path=/<...>
   */
  vueStorybook?: string;
}

export const ComponentLinks = React.memo(function ComponentLinks({
  name,
  figma,
  storybook,
  vue,
  vueStorybook
}: ComponentLinksProps) {
  const vueComponentUrl =
    vue || (name && `/components/Hse${name}/Hse${name}.html`) || '';
  return (
    <Table>
      <TableBody>
        <TableRow>
          {figma && (
            <TableCell>
              <Link href={figma} variant={Link.Variant.secondary}>
                Figma
              </Link>
            </TableCell>
          )}
          {storybook && (
            <TableCell>
              <Link
                subdomain="storybook"
                href={storybook}
                variant={Link.Variant.secondary}
              >
                Storybook
              </Link>
            </TableCell>
          )}
          {vueComponentUrl && (
            <TableCell>
              <Link
                subdomain="vue-design"
                href={vueComponentUrl}
                variant={Link.Variant.secondary}
              >
                Vue
              </Link>
            </TableCell>
          )}
          {vueStorybook && (
            <TableCell>
              <Link
                subdomain="vue-storybook"
                href={vueStorybook}
                variant={Link.Variant.secondary}
              >
                Vue Storybook
              </Link>
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  );
});
