export function applyChanges<T>(target: T, changes: Partial<T>) {
  for (const key of Object.keys(changes)) {
    target[key] = changes[key];
  }
}

export function hasAnyChange<T>(target: T, changes: Partial<T>) {
  for (const key of Object.keys(changes)) {
    if (target[key] !== changes[key]) {
      return true;
    }
  }

  return false;
}
