export enum FileButtonIconMimetype {
  'image/jpg' = 'image/jpg', // jpg
  'image/jpeg' = 'image/jpeg', // jpeg
  'image/png' = 'image/png', // png
  'application/pdf' = 'application/pdf', // pdf
  'application/zip' = 'application/zip', // zip
  'text/plain' = 'text/plain', // txt
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'application/msword' = 'application/msword', // doc
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  'application/vnd.ms-excel' = 'application/vnd.ms-excel', // xls
  // ppt
  'application/vnd.ms-powerpoint' = 'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation' = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  // mobi
  'application/x-mobipocket-ebook' = 'application/x-mobipocket-ebook',
  // fb2
  'application/fb2+xml' = 'application/fb2+xml',
  'application/fb2+zip' = 'application/fb2+zip',
  // epub
  'application/epub+zip' = 'application/epub+zip',
  // document
  'application/vnd.oasis.opendocument.text' = 'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet' = 'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.presentation' = 'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.graphics' = 'application/vnd.oasis.opendocument.graphics',
  // video
  'video/mpeg' = 'video/mpeg',
  'video/mp4' = 'video/mp4',
  'video/ogg' = 'video/ogg',
  'video/quicktime' = 'video/quicktime',
  'video/x-msvideo' = 'video/x-msvideo',
  'video/x-ms-wmv' = 'video/x-ms-wmv',
  // audio
  'audio/mpeg' = 'audio/mpeg',
  'audio/mp4' = 'audio/mp4',
  'audio/ogg' = 'audio/ogg',
  'audio/aac' = 'audio/aac'
}
