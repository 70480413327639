import Swiper, { SwiperOptions, Pagination, Autoplay } from 'swiper';

import { MixinClass } from '../../utils';
import {
  spacingSmall,
  spacingSmallX,
  breakpointTabletSmall,
  breakpointTabletLarge,
  breakpointDesktopLarge
} from '@hse-design/tokens';

type Dispatch<A> = (value: Partial<A>) => void;
type SetStateAction<S> = S | ((prevState: S) => S);
export type CarouselSetStateFn = Dispatch<SetStateAction<CarouselState>>;

const getSizeFromString = (spacing: string = '') => +spacing.replace('px', '');

const numberToSlideText = (number: number) =>
  !number ? '' : String(number).padStart(2, '0');

const mapVariantToOptions: Record<CarouselVariant, SwiperOptions> = {
  horizontalSingle: {
    direction: 'horizontal',
    breakpoints: {
      [getSizeFromString(breakpointTabletSmall)]: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      0: {
        slidesPerView: 1.12,
        spaceBetween: getSizeFromString(spacingSmallX)
      }
    }
  },
  horizontalMultiple: {
    direction: 'horizontal',
    slidesPerView: 3,
    breakpoints: {
      [getSizeFromString(breakpointDesktopLarge)]: {
        slidesPerView: 3.62,
        spaceBetween: getSizeFromString(spacingSmall)
      },
      [getSizeFromString(breakpointTabletLarge)]: {
        slidesPerView: 2.72,
        spaceBetween: getSizeFromString(spacingSmall)
      },
      [getSizeFromString(breakpointTabletSmall)]: {
        slidesPerView: 2.12,
        spaceBetween: getSizeFromString(spacingSmallX)
      },
      0: {
        slidesPerView: 1.12,
        spaceBetween: getSizeFromString(spacingSmallX)
      }
    }
  },
  fullScreen: {
    direction: 'horizontal',
    slidesPerView: 1
  }
};

export interface CarouselSlide {
  id?: string | number;
  caption?: string;
  active?: boolean;
  [k: string]: any;
}

export interface CarouselState {
  variant: CarouselVariant;
  activeSlideIndex?: number;
  $rootElement?: HTMLDivElement | null;
  autoplay?: boolean;
  autoplayInterval?: number;
  currentSlideIndex?: number;
  totalSlidesCount?: number;
  currentSlideNumberText?: string;
  totalSlidesCountText?: string;
  onChange?: (v: number) => void;
  initialSlideIndex?: number;
}

export enum CarouselVariant {
  horizontalSingle = 'horizontalSingle',
  horizontalMultiple = 'horizontalMultiple',
  fullScreen = 'fullScreen'
}

export class Carousel implements MixinClass {
  instance?: Swiper;
  setState: CarouselSetStateFn;
  activeSlideIndex?: number;
  $rootElement?: HTMLDivElement | null;
  pagination?: boolean;
  variant: CarouselVariant = CarouselVariant.fullScreen;
  prevVariant: CarouselVariant = CarouselVariant.fullScreen;
  currentSlideIndex?: number;
  totalSlidesCount?: number;
  currentSlideNumberText?: string;
  totalSlidesCountText?: string;
  onChange?: (v: number) => void;
  autoplay: boolean = false;
  autoplayInterval: number = 5000;
  initialSlideIndex: number = 0;

  constructor(setState, initialState: CarouselState) {
    this.setState = (changes: Partial<CarouselState>) => {
      setState((prevState) => {
        const newState = {
          ...prevState,
          ...changes
        };
        this.updateState(newState);
        return newState;
      });
    };
    this.setState(initialState);
    Swiper.use([Autoplay, Pagination]);
  }

  onDidMount = () => {
    this.initCarousel();
  };

  onUpdate = (state: CarouselState) => {
    this.updateState(state);
    this.updateCarousel();
  };

  onWillUnmount = () => {
    this.destroyCarousel();
  };

  updateState = (newState: CarouselState) => {
    Object.keys(newState).forEach((key) => {
      const newValue = newState[key];
      if (key === 'variant') this.prevVariant = this.variant;
      this[key] = newValue;
    });
  };

  get swiperOptions(): SwiperOptions {
    const propsOptions: SwiperOptions = {};
    if (this.autoplay) {
      propsOptions.autoplay = {
        delay: this.autoplayInterval
      };
    } else {
      propsOptions.autoplay = false;
    }

    const commonOptions: SwiperOptions = {
      simulateTouch: true,
      loop: true
    };

    return {
      initialSlide: this.initialSlideIndex,
      ...commonOptions,
      ...propsOptions,
      ...mapVariantToOptions[this.variant],
      on: {
        init: (swiper: Swiper) =>
          setTimeout(() => {
            this.dispatchResize();
            this.setSlidesState();
          })
      }
    };
  }

  initCarousel = () => {
    if (!this.$rootElement || this.instance) return;
    this.instance = new Swiper(this.$rootElement, this.swiperOptions);
    this.addCarouselListeners();
  };

  addCarouselListeners = () => {
    this.instance?.on('slideChange', (s: Swiper) => {
      this.setSlidesState();
      if (this.onChange) this.onChange(this.currentSlideIndex ?? 0);
    });
  };

  setSlidesState = () => {
    this.setState({
      currentSlideIndex: this.carouselCurrentSlideIndex,
      totalSlidesCount: this.carouselTotalSlidesCount,
      currentSlideNumberText: this.carouselCurrentSlideNumberText,
      totalSlidesCountText: this.carouselTotalSlidesCountText
    });
  };

  updateCarousel = () => {
    if (!this.instance) {
      this.initCarousel();
      return;
    }

    Object.keys(this.swiperOptions).forEach((key) => {
      (this.instance as any).params[key] = this.swiperOptions[key];
    });

    if (this.swiperOptions.loop) {
      this.instance.loopDestroy();
      this.instance.loopCreate();
    }

    this.instance.update();

    if (this.swiperOptions.autoplay) {
      this.instance.autoplay.start();
    } else {
      this.instance.autoplay.stop();
    }
  };

  onVariantUpdated = (newVariant: CarouselVariant) => {
    this.destroyCarousel();
    this.prevVariant = this.variant;
    this.variant = newVariant;
    this.initCarousel();
  };

  destroyCarousel = () => {
    if (!this.instance) return;

    this.instance.el.setAttribute('style', '');
    this.instance.el.querySelectorAll('.swiper-slide').forEach(($el) => {
      $el.setAttribute('style', '');
    });

    this.instance?.destroy();
    this.instance = undefined;
  };

  onNextClick = () => {
    this.instance?.slideNext();
  };

  onPrevClick = () => {
    this.instance?.slidePrev();
  };

  dispatchResize = () => {
    setTimeout(() => window.dispatchEvent(new Event('resize')));
  };

  get carouselCurrentSlideIndex(): number {
    return this.instance?.realIndex || 0;
  }

  get carouselCurrentSlideNumberText(): string {
    return numberToSlideText(this.carouselCurrentSlideIndex + 1);
  }

  get carouselTotalSlidesCountText(): string {
    return numberToSlideText(this.carouselTotalSlidesCount);
  }

  get carouselTotalSlidesCount(): number {
    if (!this.instance?.slides) return 1;
    // у библиотеки нет встроенной возможности узнать количество слайдов,
    // количество слайдов равно количеству элементов
    if (!this.swiperOptions.loop) return this.instance.slides.length;
    // В loop режиме слайды дублируются, поэтому определяем их количество
    // по уникальному индексу слайда (значение data-аттрибута swiper-slide-index)
    return new Set([
      ...this.instance.slides.map(($s) =>
        $s.getAttribute('data-swiper-slide-index')
      )
    ]).size;
  }
}
