import React, { useState } from 'react';
import * as iconsAll from '@hse-design/react/lib/es/icons';
import { Input, Icon, IconSize } from '@hse-design/react';
import s from './IconsDemo.module.scss';

const iconNames = Object.keys(iconsAll);
const iconsByType = [
  {
    name: 'Основные (Global)',
    icons: iconNames.filter((iconName) => iconName.startsWith('IconGlobal'))
  },
  {
    name: 'Действия (Action)',
    icons: iconNames.filter((iconName) => iconName.startsWith('IconAction'))
  },
  {
    name: 'Навигация (Navigation)',
    icons: iconNames.filter((iconName) => iconName.startsWith('IconNavigation'))
  },
  {
    name: 'Статус (Status)',
    icons: iconNames.filter((iconName) => iconName.startsWith('IconStatus'))
  },
  {
    name: 'Разделы (Section)',
    icons: iconNames.filter((iconName) => iconName.startsWith('IconSection'))
  },
  {
    name: 'Социальные сети (Social)',
    icons: iconNames.filter((iconName) => iconName.startsWith('IconSocial'))
  },
  {
    name: 'Иконки LMS',
    icons: iconNames.filter((iconName) => iconName.startsWith('IconLms'))
  },
  {
    name: 'Иконки документов',
    icons: iconNames.filter((iconName) => iconName.startsWith('IconDocument'))
  },
  {
    name: 'Иконки языков',
    icons: iconNames.filter((iconName) => iconName.startsWith('IconLanguage'))
  },

];

export function IconsDemo({ size = IconSize.medium, ...props }) {
  const [icons, setIcons] = useState(iconsByType);
  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setIcons(
      iconsByType.map(({ name, icons }) => ({
        name,
        icons: icons.filter((iconName) =>
          iconName.toLowerCase().includes(e.target.value.toLowerCase())
        )
      }))
    );
  };

  return (
    <>
      <Input
        value={searchValue}
        onChange={handleSearchChange}
        className={s.searchInput}
        placeholder="Поиск по иконкам"
      />
      {icons.map(
        (iconsType) =>
          iconsType.icons.length > 0 && (
            <div key={iconsType.name}>
              <h2>{iconsType.name}</h2>
              <div className={s.grid}>
                {iconsType.icons.map((name) => {
                  const icon = iconsAll[name];
                  return (
                    <div className={s.gridItem} key={name}>
                      <Icon icon={icon} size={size} {...props} />
                      <div className={s.gridItemTitle}>{name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )
      )}
    </>
  );
}
