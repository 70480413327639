import { IconSize, ClickableIcon, IconActionPlus, IconGlobalCalendar, IconStatusError, IconStatusLikeFilled, Size, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks, Polymorphic } from "../../../../src/common";
import * as React from 'react';
export default {
  IconSize,
  ClickableIcon,
  IconActionPlus,
  IconGlobalCalendar,
  IconStatusError,
  IconStatusLikeFilled,
  Size,
  Space,
  Playground,
  Props,
  ComponentLinks,
  Polymorphic,
  React
};