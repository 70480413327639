import { Props } from 'docz';
import { PageLayout } from '@hse-design/react';
import { ComponentLinks } from "../../../../src/common";
import { Link } from "../../../../src/common/Link.jsx";
import * as React from 'react';
export default {
  Props,
  PageLayout,
  ComponentLinks,
  Link,
  React
};