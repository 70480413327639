import { useMemo, useState } from 'react';
import { Divider, Header, HeaderHamburger, IconGlobalCalendar, IconGlobalList, IconGlobalMessage, IconSectionCorporate, IconSectionEducation, IconSectionMain, IconSectionTask, Sidebar, SidebarVariant, SidebarCustomItem, SidebarItem, SidebarItemCollapse, SidebarItemCollapseItem, Size, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks, Link } from "../../../../src/common";
import * as React from 'react';
export default {
  useMemo,
  useState,
  Divider,
  Header,
  HeaderHamburger,
  IconGlobalCalendar,
  IconGlobalList,
  IconGlobalMessage,
  IconSectionCorporate,
  IconSectionEducation,
  IconSectionMain,
  IconSectionTask,
  Sidebar,
  SidebarVariant,
  SidebarCustomItem,
  SidebarItem,
  SidebarItemCollapse,
  SidebarItemCollapseItem,
  Size,
  Space,
  Playground,
  Props,
  ComponentLinks,
  Link,
  React
};