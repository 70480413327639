import { Link } from "../../../../src/common";
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '@hse-design/react';
import * as React from 'react';
export default {
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  React
};