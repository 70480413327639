import { Content, IconActionCheck, Space, Button, Heading, Link } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { P, Link as CommonLink, ComponentLinks } from "../../../../src/common";
import * as React from 'react';
export default {
  Content,
  IconActionCheck,
  Space,
  Button,
  Heading,
  Link,
  Playground,
  Props,
  P,
  CommonLink,
  ComponentLinks,
  React
};