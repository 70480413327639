import { Icon, IconActionPlus, IconGlobalCalendar, IconStatusAttention, Size, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from "../../../../src/common";
import { colorAccentRedDark } from '@hse-design/tokens';
import * as React from 'react';
export default {
  Icon,
  IconActionPlus,
  IconGlobalCalendar,
  IconStatusAttention,
  Size,
  Space,
  Playground,
  Props,
  ComponentLinks,
  colorAccentRedDark,
  React
};