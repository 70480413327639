import { eventTargetIsElement } from '../../utils/dom';

// Используем util класс вместо миксина, так как на текущий момент в этом нет необходимости
export class SidebarUtils {
  static sidebarItemClassName = 'hse-SidebarItem';
  static sidebarCustomItemClassName = 'hse-SidebarCustomItem';
  /**
   * Должен ли сайдбар схлопываться при клике на айтем
   */
  static shouldCollapseOnItemClick = (
    e: MouseEvent,
    collapseOnItemClick?: boolean
  ) => {
    if (!collapseOnItemClick) return false;
    return e.composedPath().find(($el) => {
      return (
        eventTargetIsElement($el) &&
        ($el.classList.contains(SidebarUtils.sidebarItemClassName) ||
          $el.classList.contains(SidebarUtils.sidebarCustomItemClassName))
      );
    });
  };
}
