import {
  breakpointDesktopLarge,
  breakpointDesktopMedium,
  breakpointDesktopSmall,
  breakpointMobileLarge,
  breakpointMobileSmall,
  breakpointTabletLarge,
  breakpointTabletSmall
} from '@hse-design/tokens';

/**
 * Перечисление доступных брейкпоинтов
 */
export enum Breakpoint {
  desktopLarge = 'desktopLarge',
  desktopMedium = 'desktopMedium',
  desktopSmall = 'desktopSmall',
  tabletLarge = 'tabletLarge',
  tabletSmall = 'tabletSmall',
  mobileLarge = 'mobileLarge',
  mobileSmall = 'mobileSmall'
}

/**
 * Ширины соответствующие брейкпоинтам
 */
export const breakpointWidth = {
  [Breakpoint.desktopLarge]: parseInt(breakpointDesktopLarge),
  [Breakpoint.desktopMedium]: parseInt(breakpointDesktopMedium),
  [Breakpoint.desktopSmall]: parseInt(breakpointDesktopSmall),
  [Breakpoint.tabletLarge]: parseInt(breakpointTabletLarge),
  [Breakpoint.tabletSmall]: parseInt(breakpointTabletSmall),
  [Breakpoint.mobileLarge]: parseInt(breakpointMobileLarge),
  [Breakpoint.mobileSmall]: parseInt(breakpointMobileSmall)
};

/**
 * Упорядоченный список брейкпоинтов от большего к меньшему
 */
export const breakpointsList = [
  Breakpoint.desktopLarge,
  Breakpoint.desktopMedium,
  Breakpoint.desktopSmall,
  Breakpoint.tabletLarge,
  Breakpoint.tabletSmall,
  Breakpoint.mobileLarge,
  Breakpoint.mobileSmall
];

const breakpointIndex = (breakpoint: Breakpoint) => {
  return breakpointsList.length - breakpointsList.indexOf(breakpoint);
}

/**
 * Порядок брейкпоинтов
 */
export const breakpointsOrder = {
  [Breakpoint.desktopLarge]: breakpointIndex(Breakpoint.desktopLarge),
  [Breakpoint.desktopMedium]: breakpointIndex(Breakpoint.desktopMedium),
  [Breakpoint.desktopSmall]: breakpointIndex(Breakpoint.desktopSmall),
  [Breakpoint.tabletLarge]: breakpointIndex(Breakpoint.tabletLarge),
  [Breakpoint.tabletSmall]: breakpointIndex(Breakpoint.tabletSmall),
  [Breakpoint.mobileLarge]: breakpointIndex(Breakpoint.mobileLarge),
  [Breakpoint.mobileSmall]: breakpointIndex(Breakpoint.mobileSmall),
};

/**
 * Возвращает брейпоинт, соответствующий переданной ширине
 * @param width - ширина
 */
export function getBreakpoint(width: number|undefined): Breakpoint |null {
  if (width === undefined || width === null) {
    return null;
  }
  for (const breakpoint of breakpointsList) {
    if (width > breakpointWidth[breakpoint]) {
      return breakpoint;
    }
  }
  return Breakpoint.mobileSmall;
}

/**
 * Возвращает брейкпоинт, соответствующий текущей ширине экрана.
 * Возвращает null, если объект window недоступен (при серверном рендеринге)
 */
export function getWindowBreakpoint(): Breakpoint | null {
  if (typeof window === 'undefined') {
    return null;
  }
  return getBreakpoint(window.innerWidth);
}

/**
 * Сравнивает два брейкпоинта через <
 * @param a - левый операнд
 * @param b - правый операнд
 */
export function isBreakpointLess(a: Breakpoint, b: Breakpoint): boolean {
  const aIndex = breakpointsOrder[a];
  const bIndex = breakpointsOrder[b];
  return aIndex < bIndex;
}
