import 'focus-visible';

import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import '@hse-design/core/src/fonts.scss';
import '@hse-design/core/lib/global.css';
import '@hse-design/core/lib/index.css';
import '@hse-design/core/lib/react/index.css';
import './src/common/global.scss';
import downloadjs from 'downloadjs';

window.downloadjs = downloadjs;
