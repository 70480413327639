import { useCallback, useState } from 'react';
import { Button, LabeledSpinner, Size, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks, FigmaEmbed } from "../../../../src/common";
import * as React from 'react';
export default {
  useCallback,
  useState,
  Button,
  LabeledSpinner,
  Size,
  Space,
  Playground,
  Props,
  ComponentLinks,
  FigmaEmbed,
  React
};