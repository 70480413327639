import { Img } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from "../../../../src/common";
import * as React from 'react';
export default {
  Img,
  Playground,
  Props,
  ComponentLinks,
  React
};