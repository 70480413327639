import { ContentHeading, ContentLi, ContentOl, ContentUl, Li, Ol, Paragraph, Ul } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from "../../../../src/common";
import * as React from 'react';
export default {
  ContentHeading,
  ContentLi,
  ContentOl,
  ContentUl,
  Li,
  Ol,
  Paragraph,
  Ul,
  Playground,
  Props,
  ComponentLinks,
  React
};