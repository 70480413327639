import React from 'react';
import * as fontFamily from '@hse-design/tokens/lib/es/fontFamily.module.js';
import * as fontSize from '@hse-design/tokens/lib/es/fontSize.module.js';
import * as fontWeight from '@hse-design/tokens/lib/es/fontWeight.module.js';
import * as lineHeight from '@hse-design/tokens/lib/es/lineHeight.module.js';
import decamelize from 'decamelize';
import s from './TypographyDemo.module.scss';

const toCamelCase = (str = '') =>
  str.replace(/([-_][a-z])/gi, ($1) => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });

const lowerFirst = ([firstLetter, ...rest] = '') =>
  `${firstLetter.toLowerCase()}${rest}`;

const getClassNameFromMixinName = (mixinName) => {
  let str = toCamelCase(mixinName);
  str = str.replace('text', '');
  return `hseText_${lowerFirst(str)}`;
};

const tokens = {
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight
};
const mixinText = {
  text_heading_h1: 'Заголовок первого уровня',
  text_heading_h2: 'Заголовок второго уровня',
  text_heading_h3: 'Заголовок третьего уровня',
  text_heading_h4: 'Заголовок четвертого уровня',
  text_lead_large: 'Лид с большим размером шрифта',
  text_lead_small: 'Лид с маленьким размером шрифта',
  text_paragraph_large: 'Стандартный абзац текста с большим размером шрифта',
  text_paragraph_small: 'Стандартный абзац текста с маленьким размером шрифта',
  text_caption_x_large_regular:
    'Подпись с нормальным начертанием и большим размером шрифта ',
  text_caption_x_large_bold:
    'Подпись с жирным начертанием и большим размером шрифта',
  text_caption_large_regular:
    'Подпись с нормальным начертанием и большим размером шрифта ',
  text_caption_large_bold:
    'Подпись с жирным начертанием и большим размером шрифта',
  text_caption_medium_regular:
    'Подпись с нормальным начертанием и средним размером шрифта',
  text_caption_medium_bold:
    'Подпись с жирным начертанием и средним размером шрифта',
  text_caption_small_regular:
    'Подпись с нормальным начертанием и маленьким размером шрифта',
  text_caption_small_bold:
    'Подпись с жирным начертанием и маленьким размером шрифта',
  text_caption_x_small_regular:
    'Подпись с нормальным начертанием и очень маленьким размером шрифта',
  text_caption_x_small_bold:
    'Подпись с жирным начертанием и очень маленьким размером шрифта',
  text_label_x_small_regular:
    'Пометка с нормальным начертанием и очень маленьким размером шрифта',
  text_label_x_small_bold:
    'Пометка с жирным начертанием и очень маленьким размером шрифта'
};
const text =
  'За 25 с лишним лет Высшая школа экономики стала для многих символом качественного образования.';
const header = 'Высшая школа экономики';

export function TypographyDemo({ tokenName }) {
  const tokenValues = tokens[tokenName];
  const headerTokens = ['fontSize56', 'fontSize42', 'fontSize32'];
  return (
    <table>
      <tbody>
        {Object.keys(tokenValues).map((tokenItem) => (
          <tr key={tokenItem}>
            <td
              className={s.tokenDemo}
              style={{ [tokenName]: tokenValues[tokenItem] }}
            >
              {tokenName === 'fontSize' && headerTokens.includes(tokenItem)
                ? header
                : text}
            </td>
            <td className={s.tokenInfo}>
              {decamelize(tokenItem, '-').replace(/([0-9]+)/gi, '-$1')}
            </td>
            <td className={s.tokenInfo}>{tokenValues[tokenItem]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function TypographyMixinsDemo() {
  return (
    <table>
      <tbody>
        {Object.keys(mixinText).map((mixin) => (
          <tr key={mixin}>
            <td
              className={s[getClassNameFromMixinName(mixin)]}
              style={{ paddingBottom: '20px' }}
            >
              {`${mixinText[mixin]} (${mixin})`}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
