export const paddingMobileSmall = "16px";
export const paddingMobileLarge = "16px";
export const paddingTabletSmall = "72px";
export const paddingTabletLarge = "40px";
export const paddingTabletLargeWithSidebarFixed = "32px";
export const paddingTabletLargeWithSidebarDynamic = "48px";
export const paddingDesktopSmall = "76px";
export const paddingDesktopSmallWithSidebarFixed = "32px";
export const paddingDesktopSmallWithSidebarDynamic = "48px";
export const paddingDesktopMedium = "112px";
export const paddingDesktopMediumWithSidebarFixed = "32px";
export const paddingDesktopMediumWithSidebarDynamic = "48px";
export const paddingDesktopLarge = "144px";
export const paddingDesktopLargeWithSidebarFixed = "32px";
export const paddingDesktopLargeWithSidebarDynamic = "48px";