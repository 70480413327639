export const spacingSmall3X = "8px";
export const spacingSmall2X = "12px";
export const spacingSmallX = "16px";
export const spacingSmall = "24px";
export const spacingMedium = "32px";
export const spacingMediumX = "40px";
export const spacingLarge = "48px";
export const spacingLargeX = "64px";
export const spacingLarge2X = "72px";
export const spacingLarge3X = "96px";
export const spacingLarge4X = "124px";