export const colorAccentDodgerDeepdark = "rgb(0, 101, 176)";
export const colorAccentDodgerDark = "rgb(0, 142, 246)";
export const colorAccentDodger = "rgb(39, 163, 255)";
export const colorAccentDodger1 = "rgb(39, 163, 255)";
export const colorAccentDodger2 = "rgb(108, 180, 245)";
export const colorAccentDodger3 = "rgb(144, 195, 240)";
export const colorAccentDodger4 = "rgb(195, 221, 244)";
export const colorAccentDodger5 = "rgb(235, 243, 250)";
export const colorAccentSlateblueDeepdark = "rgb(88, 26, 221)";
export const colorAccentSlateblueDark = "rgb(102, 80, 236)";
export const colorAccentSlateblue = "rgb(110, 115, 247)";
export const colorAccentSlateblue1 = "rgb(110, 115, 247)";
export const colorAccentSlateblue2 = "rgb(148, 151, 255)";
export const colorAccentSlateblue3 = "rgb(178, 182, 255)";
export const colorAccentSlateblue4 = "rgb(204, 206, 255)";
export const colorAccentSlateblue5 = "rgb(240, 240, 250)";
export const colorAccentPurpleDeepdark = "rgb(59, 11, 128)";
export const colorAccentPurpleDark = "rgb(105, 41, 196)";
export const colorAccentPurple = "rgb(147, 92, 229)";
export const colorAccentPurple1 = "rgb(147, 92, 229)";
export const colorAccentPurple2 = "rgb(163, 127, 245)";
export const colorAccentPurple3 = "rgb(194, 169, 249)";
export const colorAccentPurple4 = "rgb(216, 201, 251)";
export const colorAccentPurple5 = "rgb(243, 240, 250)";
export const colorAccentRedDeepdark = "rgb(164, 47, 21)";
export const colorAccentRedDark = "rgb(224, 58, 21)";
export const colorAccentRed = "rgb(240, 82, 43)";
export const colorAccentRed1 = "rgb(240, 82, 43)";
export const colorAccentRed2 = "rgb(255, 108, 71)";
export const colorAccentRed3 = "rgb(255, 141, 112)";
export const colorAccentRed4 = "rgb(255, 190, 173)";
export const colorAccentRed5 = "rgb(255, 238, 234)";
export const colorAccentOrangeDeepdark = "rgb(150, 64, 2)";
export const colorAccentOrangeDark = "rgb(235, 109, 0)";
export const colorAccentOrange = "rgb(250, 133, 0)";
export const colorAccentOrange1 = "rgb(250, 133, 0)";
export const colorAccentOrange2 = "rgb(255, 148, 26)";
export const colorAccentOrange3 = "rgb(255, 179, 102)";
export const colorAccentOrange4 = "rgb(255, 204, 153)";
export const colorAccentOrange5 = "rgb(255, 243, 230)";
export const colorAccentGreenDeepdark = "rgb(14, 96, 61)";
export const colorAccentGreenDark = "rgb(0, 163, 95)";
export const colorAccentGreen = "rgb(0, 184, 110)";
export const colorAccentGreen1 = "rgb(0, 184, 110)";
export const colorAccentGreen2 = "rgb(73, 184, 139)";
export const colorAccentGreen3 = "rgb(134, 209, 179)";
export const colorAccentGreen4 = "rgb(175, 225, 205)";
export const colorAccentGreen5 = "rgb(235, 245, 241)";