import React from 'react';
import { P } from './P';
import { Link } from './Link';

export function Polymorphic({ component, prop = 'component' }) {
  return (
    <P>
      Компонент {component} полиморфный: вы можете задать произвольный корневой
      элемент, передав компонент или тег в проп <code>{prop}</code>. Также
      вы можете прочитать про полиморфизм{' '}
      <Link to="/components#полиморфизм">в разделе принципов компонентов</Link>.
    </P>
  );
}
