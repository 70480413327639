import { GridTable, GridWithSidebarTable } from "../../../../src/common";
import ModuleImg from "../../../../src/assets/module.svg";
import GridImg from "../../../../src/assets/grid.svg";
import SidebarImg from "../../../../src/assets/sidebar.svg";
import * as React from 'react';
export default {
  GridTable,
  GridWithSidebarTable,
  ModuleImg,
  GridImg,
  SidebarImg,
  React
};