// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-alert-mdx": () => import("./../../../src/components/Alert.mdx" /* webpackChunkName: "component---src-components-alert-mdx" */),
  "component---src-components-attention-mdx": () => import("./../../../src/components/Attention.mdx" /* webpackChunkName: "component---src-components-attention-mdx" */),
  "component---src-components-badge-icon-mdx": () => import("./../../../src/components/BadgeIcon.mdx" /* webpackChunkName: "component---src-components-badge-icon-mdx" */),
  "component---src-components-badge-label-mdx": () => import("./../../../src/components/BadgeLabel.mdx" /* webpackChunkName: "component---src-components-badge-label-mdx" */),
  "component---src-components-blockquote-mdx": () => import("./../../../src/components/Blockquote.mdx" /* webpackChunkName: "component---src-components-blockquote-mdx" */),
  "component---src-components-breadcrumbs-mdx": () => import("./../../../src/components/Breadcrumbs.mdx" /* webpackChunkName: "component---src-components-breadcrumbs-mdx" */),
  "component---src-components-button-mdx": () => import("./../../../src/components/Button.mdx" /* webpackChunkName: "component---src-components-button-mdx" */),
  "component---src-components-button-popover-mdx": () => import("./../../../src/components/ButtonPopover.mdx" /* webpackChunkName: "component---src-components-button-popover-mdx" */),
  "component---src-components-calendar-mdx": () => import("./../../../src/components/Calendar.mdx" /* webpackChunkName: "component---src-components-calendar-mdx" */),
  "component---src-components-card-mdx": () => import("./../../../src/components/Card.mdx" /* webpackChunkName: "component---src-components-card-mdx" */),
  "component---src-components-carousel-mdx": () => import("./../../../src/components/Carousel.mdx" /* webpackChunkName: "component---src-components-carousel-mdx" */),
  "component---src-components-checkbox-mdx": () => import("./../../../src/components/Checkbox.mdx" /* webpackChunkName: "component---src-components-checkbox-mdx" */),
  "component---src-components-clickable-icon-mdx": () => import("./../../../src/components/ClickableIcon.mdx" /* webpackChunkName: "component---src-components-clickable-icon-mdx" */),
  "component---src-components-collapse-mdx": () => import("./../../../src/components/Collapse.mdx" /* webpackChunkName: "component---src-components-collapse-mdx" */),
  "component---src-components-columns-mdx": () => import("./../../../src/components/Columns.mdx" /* webpackChunkName: "component---src-components-columns-mdx" */),
  "component---src-components-content-mdx": () => import("./../../../src/components/Content.mdx" /* webpackChunkName: "component---src-components-content-mdx" */),
  "component---src-components-date-time-picker-mdx": () => import("./../../../src/components/DateTimePicker.mdx" /* webpackChunkName: "component---src-components-date-time-picker-mdx" */),
  "component---src-components-divider-mdx": () => import("./../../../src/components/Divider.mdx" /* webpackChunkName: "component---src-components-divider-mdx" */),
  "component---src-components-dropdown-menu-mdx": () => import("./../../../src/components/DropdownMenu.mdx" /* webpackChunkName: "component---src-components-dropdown-menu-mdx" */),
  "component---src-components-error-mdx": () => import("./../../../src/components/Error.mdx" /* webpackChunkName: "component---src-components-error-mdx" */),
  "component---src-components-file-button-mdx": () => import("./../../../src/components/FileButton.mdx" /* webpackChunkName: "component---src-components-file-button-mdx" */),
  "component---src-components-file-input-mdx": () => import("./../../../src/components/FileInput.mdx" /* webpackChunkName: "component---src-components-file-input-mdx" */),
  "component---src-components-footer-mdx": () => import("./../../../src/components/Footer.mdx" /* webpackChunkName: "component---src-components-footer-mdx" */),
  "component---src-components-grid-mdx": () => import("./../../../src/components/Grid.mdx" /* webpackChunkName: "component---src-components-grid-mdx" */),
  "component---src-components-header-mdx": () => import("./../../../src/components/Header.mdx" /* webpackChunkName: "component---src-components-header-mdx" */),
  "component---src-components-heading-mdx": () => import("./../../../src/components/Heading.mdx" /* webpackChunkName: "component---src-components-heading-mdx" */),
  "component---src-components-icon-button-mdx": () => import("./../../../src/components/IconButton.mdx" /* webpackChunkName: "component---src-components-icon-button-mdx" */),
  "component---src-components-icon-mdx": () => import("./../../../src/components/Icon.mdx" /* webpackChunkName: "component---src-components-icon-mdx" */),
  "component---src-components-image-cropper-mdx": () => import("./../../../src/components/ImageCropper.mdx" /* webpackChunkName: "component---src-components-image-cropper-mdx" */),
  "component---src-components-img-mdx": () => import("./../../../src/components/Img.mdx" /* webpackChunkName: "component---src-components-img-mdx" */),
  "component---src-components-input-mdx": () => import("./../../../src/components/Input.mdx" /* webpackChunkName: "component---src-components-input-mdx" */),
  "component---src-components-labeled-progress-bar-mdx": () => import("./../../../src/components/LabeledProgressBar.mdx" /* webpackChunkName: "component---src-components-labeled-progress-bar-mdx" */),
  "component---src-components-labeled-spinner-mdx": () => import("./../../../src/components/LabeledSpinner.mdx" /* webpackChunkName: "component---src-components-labeled-spinner-mdx" */),
  "component---src-components-lightbox-mdx": () => import("./../../../src/components/Lightbox.mdx" /* webpackChunkName: "component---src-components-lightbox-mdx" */),
  "component---src-components-link-abbr-mdx": () => import("./../../../src/components/LinkAbbr.mdx" /* webpackChunkName: "component---src-components-link-abbr-mdx" */),
  "component---src-components-link-mdx": () => import("./../../../src/components/Link.mdx" /* webpackChunkName: "component---src-components-link-mdx" */),
  "component---src-components-logo-digital-mdx": () => import("./../../../src/components/LogoDigital.mdx" /* webpackChunkName: "component---src-components-logo-digital-mdx" */),
  "component---src-components-mdx": () => import("./../../../src/components.mdx" /* webpackChunkName: "component---src-components-mdx" */),
  "component---src-components-mobile-menu-mdx": () => import("./../../../src/components/MobileMenu.mdx" /* webpackChunkName: "component---src-components-mobile-menu-mdx" */),
  "component---src-components-modal-mdx": () => import("./../../../src/components/Modal.mdx" /* webpackChunkName: "component---src-components-modal-mdx" */),
  "component---src-components-month-picker-mdx": () => import("./../../../src/components/MonthPicker.mdx" /* webpackChunkName: "component---src-components-month-picker-mdx" */),
  "component---src-components-multi-file-input-mdx": () => import("./../../../src/components/MultiFileInput.mdx" /* webpackChunkName: "component---src-components-multi-file-input-mdx" */),
  "component---src-components-page-layout-mdx": () => import("./../../../src/components/PageLayout.mdx" /* webpackChunkName: "component---src-components-page-layout-mdx" */),
  "component---src-components-pagination-mdx": () => import("./../../../src/components/Pagination.mdx" /* webpackChunkName: "component---src-components-pagination-mdx" */),
  "component---src-components-paragraph-mdx": () => import("./../../../src/components/Paragraph.mdx" /* webpackChunkName: "component---src-components-paragraph-mdx" */),
  "component---src-components-person-mdx": () => import("./../../../src/components/Person.mdx" /* webpackChunkName: "component---src-components-person-mdx" */),
  "component---src-components-popover-base-mdx": () => import("./../../../src/components/PopoverBase.mdx" /* webpackChunkName: "component---src-components-popover-base-mdx" */),
  "component---src-components-popover-mdx": () => import("./../../../src/components/Popover.mdx" /* webpackChunkName: "component---src-components-popover-mdx" */),
  "component---src-components-popper-mdx": () => import("./../../../src/components/Popper.mdx" /* webpackChunkName: "component---src-components-popper-mdx" */),
  "component---src-components-progress-bar-mdx": () => import("./../../../src/components/ProgressBar.mdx" /* webpackChunkName: "component---src-components-progress-bar-mdx" */),
  "component---src-components-radio-mdx": () => import("./../../../src/components/Radio.mdx" /* webpackChunkName: "component---src-components-radio-mdx" */),
  "component---src-components-range-date-picker-mdx": () => import("./../../../src/components/RangeDatePicker.mdx" /* webpackChunkName: "component---src-components-range-date-picker-mdx" */),
  "component---src-components-range-slider-mdx": () => import("./../../../src/components/RangeSlider.mdx" /* webpackChunkName: "component---src-components-range-slider-mdx" */),
  "component---src-components-rich-input-mdx": () => import("./../../../src/components/RichInput.mdx" /* webpackChunkName: "component---src-components-rich-input-mdx" */),
  "component---src-components-scroll-area-mdx": () => import("./../../../src/components/ScrollArea.mdx" /* webpackChunkName: "component---src-components-scroll-area-mdx" */),
  "component---src-components-segmented-control-mdx": () => import("./../../../src/components/SegmentedControl.mdx" /* webpackChunkName: "component---src-components-segmented-control-mdx" */),
  "component---src-components-select-mdx": () => import("./../../../src/components/Select.mdx" /* webpackChunkName: "component---src-components-select-mdx" */),
  "component---src-components-side-pane-mdx": () => import("./../../../src/components/SidePane.mdx" /* webpackChunkName: "component---src-components-side-pane-mdx" */),
  "component---src-components-sidebar-mdx": () => import("./../../../src/components/Sidebar.mdx" /* webpackChunkName: "component---src-components-sidebar-mdx" */),
  "component---src-components-single-date-picker-mdx": () => import("./../../../src/components/SingleDatePicker.mdx" /* webpackChunkName: "component---src-components-single-date-picker-mdx" */),
  "component---src-components-single-time-picker-mdx": () => import("./../../../src/components/SingleTimePicker.mdx" /* webpackChunkName: "component---src-components-single-time-picker-mdx" */),
  "component---src-components-space-mdx": () => import("./../../../src/components/Space.mdx" /* webpackChunkName: "component---src-components-space-mdx" */),
  "component---src-components-spinner-mdx": () => import("./../../../src/components/Spinner.mdx" /* webpackChunkName: "component---src-components-spinner-mdx" */),
  "component---src-components-switcher-mdx": () => import("./../../../src/components/Switcher.mdx" /* webpackChunkName: "component---src-components-switcher-mdx" */),
  "component---src-components-table-mdx": () => import("./../../../src/components/Table.mdx" /* webpackChunkName: "component---src-components-table-mdx" */),
  "component---src-components-tabs-mdx": () => import("./../../../src/components/Tabs.mdx" /* webpackChunkName: "component---src-components-tabs-mdx" */),
  "component---src-components-tag-mdx": () => import("./../../../src/components/Tag.mdx" /* webpackChunkName: "component---src-components-tag-mdx" */),
  "component---src-components-tap-bar-mdx": () => import("./../../../src/components/TapBar.mdx" /* webpackChunkName: "component---src-components-tap-bar-mdx" */),
  "component---src-components-textarea-mdx": () => import("./../../../src/components/Textarea.mdx" /* webpackChunkName: "component---src-components-textarea-mdx" */),
  "component---src-components-toast-mdx": () => import("./../../../src/components/Toast.mdx" /* webpackChunkName: "component---src-components-toast-mdx" */),
  "component---src-components-tooltip-mdx": () => import("./../../../src/components/Tooltip.mdx" /* webpackChunkName: "component---src-components-tooltip-mdx" */),
  "component---src-components-ul-ol-li-mdx": () => import("./../../../src/components/Ul-Ol-Li.mdx" /* webpackChunkName: "component---src-components-ul-ol-li-mdx" */),
  "component---src-components-user-menu-mdx": () => import("./../../../src/components/UserMenu.mdx" /* webpackChunkName: "component---src-components-user-menu-mdx" */),
  "component---src-components-year-picker-mdx": () => import("./../../../src/components/YearPicker.mdx" /* webpackChunkName: "component---src-components-year-picker-mdx" */),
  "component---src-contacts-mdx": () => import("./../../../src/contacts.mdx" /* webpackChunkName: "component---src-contacts-mdx" */),
  "component---src-icons-mdx": () => import("./../../../src/icons.mdx" /* webpackChunkName: "component---src-icons-mdx" */),
  "component---src-illustrations-mdx": () => import("./../../../src/illustrations.mdx" /* webpackChunkName: "component---src-illustrations-mdx" */),
  "component---src-index-mdx": () => import("./../../../src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---src-installation-mdx": () => import("./../../../src/installation.mdx" /* webpackChunkName: "component---src-installation-mdx" */),
  "component---src-logo-mdx": () => import("./../../../src/logo.mdx" /* webpackChunkName: "component---src-logo-mdx" */),
  "component---src-releases-mdx": () => import("./../../../src/releases.mdx" /* webpackChunkName: "component---src-releases-mdx" */),
  "component---src-setup-design-mdx": () => import("./../../../src/setupDesign.mdx" /* webpackChunkName: "component---src-setup-design-mdx" */),
  "component---src-tokens-color-mdx": () => import("./../../../src/tokens/color.mdx" /* webpackChunkName: "component---src-tokens-color-mdx" */),
  "component---src-tokens-grid-mdx": () => import("./../../../src/tokens/grid.mdx" /* webpackChunkName: "component---src-tokens-grid-mdx" */),
  "component---src-tokens-index-mdx": () => import("./../../../src/tokens/index.mdx" /* webpackChunkName: "component---src-tokens-index-mdx" */),
  "component---src-tokens-other-mdx": () => import("./../../../src/tokens/other.mdx" /* webpackChunkName: "component---src-tokens-other-mdx" */),
  "component---src-tokens-typography-mdx": () => import("./../../../src/tokens/typography.mdx" /* webpackChunkName: "component---src-tokens-typography-mdx" */)
}

