import { transitionQuick } from '@hse-design/tokens';

enum BubbleState {
  created = 'created',
  activating = 'activating',
  active = 'active',
  fading = 'fading'
}

export class Bubble {
  id: number;
  div?: HTMLDivElement;
  state: BubbleState = BubbleState.created;
  onRemove: (id: number) => void;
  shouldFade = false;
  timeout?: any;

  static classBubble = 'hse-Button__bubble';
  static classActive = 'hse-Button__bubble_active';
  static classFading = 'hse-Button__bubble_fading';
  static activeTime = parseFloat(transitionQuick) * 1000;
  static fadeTime = parseFloat(transitionQuick) * 1000;

  constructor(id: number, onRemove: (id: number) => void) {
    const bubbleDiv = document.createElement('div');
    bubbleDiv.classList.add(Bubble.classBubble);
    this.div = bubbleDiv;
    this.id = id;
    this.onRemove = onRemove;

    this.timeout = setTimeout(() => {
      this.state = BubbleState.activating;
      bubbleDiv?.classList.add(Bubble.classActive);
      this.timeout = setTimeout(() => {
        this.timeout = null;
        this.state = BubbleState.active;
        if (this.shouldFade) {
          this.fade();
        }
      }, Bubble.activeTime);
    }, 0);
  }

  clear() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (this.div) {
      this.div.remove();
      this.div = undefined;
    }
  }

  fade() {
    if (this.state === BubbleState.active) {
      this.div?.classList.add(Bubble.classFading);
      this.state = BubbleState.fading;
      this.timeout = setTimeout(() => {
        this.timeout = null;
        if (this.div) {
          this.div.remove();
          this.div = undefined;
        }
        this.onRemove(this.id);
      }, Bubble.fadeTime);
    } else {
      this.shouldFade = true;
    }
  }
}
