import { IconActionCheck, Space, Tag } from '@hse-design/react';
import { spacingSmall, colorBrand } from '@hse-design/tokens';
import { Playground, Props } from 'docz';
import { ComponentLinks, FigmaEmbed } from "../../../../src/common";
import * as React from 'react';
export default {
  IconActionCheck,
  Space,
  Tag,
  spacingSmall,
  colorBrand,
  Playground,
  Props,
  ComponentLinks,
  FigmaEmbed,
  React
};