import { SetStateFn } from './types';

export function setState<State>(setState: SetStateFn<State>) {
  return (changes: Partial<State>) => {
    setState((prevState: State) => ({
      ...prevState,
      ...changes
    }));
  };
}
