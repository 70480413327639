import React from 'react';
import { Link as HseLink } from '@hse-design/react';
import { Link as GatsbyLink } from 'gatsby';
import {
  doczUrl,
  storybookUrl,
  vueDoczUrl,
  vueStorybookUrl,
  figmaUrl
} from '../config';

const buildUrl = (subdomain) => {
  switch (subdomain) {
    case 'design':
      return doczUrl;
    case 'storybook':
      return storybookUrl;
    case 'vue-design':
      return vueDoczUrl;
    case 'vue-storybook':
      return vueStorybookUrl;
    case 'figma':
      return figmaUrl;
    default:
      console.warn(`Linking to unknown destination '${subdomain}'`);
      return '';
  }
};

export function Link({
  subdomain = '',
  href = '',
  to = '',
  children,
  ...restProps
}) {
  const props = {
    ...restProps
  };

  if (to) {
    props.to = to;
    props.component = GatsbyLink;
  } else {
    props.target = '_blank';
    props.rel = 'noopener noreferrer';
    props.href = (subdomain ? buildUrl(subdomain) : '') + href;
  }

  return <HseLink {...props}>{children || url}</HseLink>;
}

Link.Variant = HseLink.Variant;
