import { FileButton, FileButtonIcon, Size, Space } from '@hse-design/react';
import { ComponentLinks, Polymorphic } from "../../../../src/common";
import { Playground, Props } from 'docz';
import * as React from 'react';
export default {
  FileButton,
  FileButtonIcon,
  Size,
  Space,
  ComponentLinks,
  Polymorphic,
  Playground,
  Props,
  React
};