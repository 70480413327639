import { useState } from 'react';
import { BadgeIcon, Space, Size, IconActionCheck } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks, Polymorphic } from "../../../../src/common";
import * as React from 'react';
export default {
  useState,
  BadgeIcon,
  Space,
  Size,
  IconActionCheck,
  Playground,
  Props,
  ComponentLinks,
  Polymorphic,
  React
};