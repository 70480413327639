import { Key } from 'ts-keycode-enum';
import { BubbleManager } from './BubbleManager';
import { MixinClass } from '../../utils';
import { Optional } from '../../utils/types';

export class InteractionBubbles implements MixinClass {
  bubbles = new BubbleManager();
  enabled = false;
  isSpaceDown = false;

  static create() {
    return new InteractionBubbles();
  }

  onWillUnmount() {
    document.removeEventListener('mouseup', this.fadeBubble);
    this.bubbles.onWillUnmount();
  }

  update({
    enabled,
    root
  }: {
    root: Optional<HTMLDivElement>;
    enabled: boolean;
  }) {
    this.enabled = enabled;
    this.bubbles.update({ root });
  }

  createBubble = () => {
    if (this.enabled) {
      this.bubbles.createBubble();
    }
  };

  onMouseDown = () => {
    this.createBubble();

    document.addEventListener('mouseup', this.fadeBubble, {
      once: true
    });
  };

  fadeBubble = () => this.bubbles.fadeBubbles();

  onKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === Key.Space) {
      if (!this.isSpaceDown) {
        this.isSpaceDown = true;
        this.createBubble();
      }
    }
    if (event.keyCode === Key.Enter) {
      this.createBubble();
    }
  };

  onClick = () => {
    this.isSpaceDown = false;
    this.bubbles.fadeBubbles();
  };
}
