import { Button, Paragraph, ContentAttention, ContentBlockquote, ContentCollapse, ContentColumns, ContentHeading, ContentImg, ContentLi, ContentOl, ContentParagraph, ContentUl, Link, Space, Table, TableBody, TableCell, TableFooter, TableHead, TableHeadCell, TableRow } from '@hse-design/react';
import { Playground } from 'docz';
import { ComponentLinks } from "../../../../src/common";
import * as React from 'react';
export default {
  Button,
  Paragraph,
  ContentAttention,
  ContentBlockquote,
  ContentCollapse,
  ContentColumns,
  ContentHeading,
  ContentImg,
  ContentLi,
  ContentOl,
  ContentParagraph,
  ContentUl,
  Link,
  Space,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeadCell,
  TableRow,
  Playground,
  ComponentLinks,
  React
};