import { MixinClass } from '../../utils';
import { Key } from 'ts-keycode-enum';
import { applyChanges } from '../../utils/changes';

export interface ISegmentedControlItem {
  value: any;
  label?: any;
  inactive?: boolean;
  disabled?: boolean;
}

export interface ISegmentedControlState {
  focusedItemValue?: ISegmentedControlItem['value'];
}

export interface ISegmentedControlProps {
  items?: ISegmentedControlItem[];
  currentItemValue?: ISegmentedControlItem['value'];
}

export interface ISegmentedControlStateHandlers {
  onChange?: (value: string) => void;
}

enum Direction {
  Left = 'left',
  Right = 'right'
}

type Dispatch<A> = (value: Partial<A>) => void;
type SetStateAction<S> = S | ((prevState: S) => S);
export type SegmentedControlSetStateFn = Dispatch<SetStateAction<ISegmentedControlState>>;

export class SegmentedControlState implements MixinClass {
  static initialState: ISegmentedControlState = {
    focusedItemValue: undefined
  };

  props: ISegmentedControlProps = {
    items: [],
    currentItemValue: undefined
  };

  state: ISegmentedControlState = { ...SegmentedControlState.initialState };

  listeners: ISegmentedControlStateHandlers = {
    onChange: undefined
  };

  setState: SegmentedControlSetStateFn;

  constructor(
    setState
  ) {
    this.setState = (changes: Partial<ISegmentedControlState> = {}) => {
      setState((prevState: ISegmentedControlState = {}) => {
        return {
          ...prevState,
          ...changes
        };
      });
    };
  }

  onDidMount() {
  }

  onWillUnmount() {
  }

  private getNextItem(currentIndex: number, direction: Direction) {
    let nextIndex = -1;

    if (direction === Direction.Right) {
      nextIndex = currentIndex + 1;
    } else if (direction === Direction.Left) {
      nextIndex = currentIndex - 1;
    }

    if (nextIndex === -1) return undefined;

    const nextItem = this.props.items?.[nextIndex];
    if (nextItem && (this.isItemInactive(nextItem))) {
      return this.getNextItem(nextIndex, direction);
    }

    return nextItem;
  }

  changeCurrentItemValue(itemValue: ISegmentedControlItem['value']) {
    this.listeners.onChange?.(itemValue);
    this.changeFocusedItemValue(itemValue);
  }

  changeFocusedItemValue(focusedItemValue: ISegmentedControlItem['value']) {
    this.setState({ focusedItemValue });
  }

  update(state: ISegmentedControlState, props: ISegmentedControlProps, listeners: ISegmentedControlStateHandlers) {
    applyChanges(this.state, state);
    applyChanges(this.props, props);
    applyChanges(this.listeners, listeners);
  }

  isItemInactive(item: ISegmentedControlItem) {
    return item.inactive || item.disabled;
  }

  onKeyDown(e: KeyboardEvent) {
    const { keyCode } = e;
    if (![Key.LeftArrow, Key.RightArrow, Key.Space, Key.Enter].includes(keyCode)) return;
    if (keyCode === Key.Space || keyCode === Key.Enter) e.preventDefault();
    const focusedItemValue = this.state.focusedItemValue ?? this.props.currentItemValue ?? 0;

    let currentIndex = this.props.items?.findIndex(
      (i: ISegmentedControlItem) => i.value === focusedItemValue
    );
    currentIndex = (!currentIndex || currentIndex === -1) ? 0 : currentIndex;

    const currentItem = this.props.items?.[currentIndex];
    let newItem: ISegmentedControlItem | undefined = undefined;

    if (keyCode === Key.Space && focusedItemValue) {
      return this.changeCurrentItemValue(focusedItemValue);
    }

    // Если текущий сегмент не существует, или находится в состояниях disabled/inactive
    // Находим первый возможный для выбора
    if (
      typeof currentItem === 'undefined' || this.isItemInactive(currentItem)
    ) {
      newItem = this.props.items?.find((x) => !this.isItemInactive(x));
    } else if (keyCode === Key.RightArrow) {
      newItem = this.getNextItem(currentIndex, Direction.Right);
    } else if (keyCode === Key.LeftArrow) {
      newItem = this.getNextItem(currentIndex, Direction.Left);
    }

    if (newItem && newItem.value) this.changeFocusedItemValue(newItem.value);
  }

  onClick(newValue: ISegmentedControlItem['value']) {
    this.changeCurrentItemValue(newValue);
  }

  onBlur() {
    this.changeFocusedItemValue(undefined);
  }
}
