export enum TagSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export enum TagVariant {
  primary = 'primary',
  primaryInvert = 'primaryInvert',
  secondary = 'secondary',
  secondaryInvert = 'secondaryInvert'
}
