import { MixinClass, MixinClassEffect, SetStateFn } from '../utils';
import { applyChanges } from '../utils/changes';

export enum FileInputSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export type FileInputProgress = 'indeterminate' | number | boolean;

export interface IFileInputState {
  dropzoneActive?: boolean;
  dropzoneHovered?: boolean;
  files?: FileList | null;
}

export interface IFileInputStateProps {
  onFileSelect?: (files: FileList | null) => void;
}

export class FileInputState implements MixinClass {
  static initialState: IFileInputState = {
    dropzoneActive: false,
    dropzoneHovered: false
  };

  constructor(setState: SetStateFn<IFileInputState>) {
    this.setState = (changes: Partial<IFileInputState>) => {
      const isStateChanged = Object.entries(changes).some(
        ([key, value]) => this.state[key] !== value
      );
      if (isStateChanged) {
        setState((prevState) => ({
          ...prevState,
          ...changes
        }));
      }
    };
  }

  private $rootElement?: HTMLElement;
  private $inputElement?: HTMLInputElement;
  private state: IFileInputState = { ...FileInputState.initialState };
  private props: IFileInputStateProps = {};
  private readonly setState: (changes: Partial<IFileInputState>) => void;

  onDragEnter = (e: DragEvent) => {
    const target = e.target as Node;
    const targetThis = !!(target && this.$rootElement?.contains(target));

    if (targetThis) {
      e.preventDefault();
    }

    this.setState({
      dropzoneActive: true,
      dropzoneHovered: targetThis
    });
  };

  onDragLeave = (e: DragEvent) => {
    const target = e.target as Node;
    const targetThis = !!(target && this.$rootElement?.contains(target));

    this.setState({
      dropzoneActive: !!e.relatedTarget,
      dropzoneHovered: targetThis
    });
  };

  onDragEnd = () => {
    this.setState({
      dropzoneActive: false,
      dropzoneHovered: false
    });
  };

  onDrop = (e: DragEvent) => {
    e.preventDefault();
    const files = e?.dataTransfer?.files || null;
    this.setState({
      dropzoneActive: false,
      dropzoneHovered: false,
      files
    });
    if (this.$inputElement) {
      this.$inputElement.files = files;
    }
    this.props.onFileSelect?.(files);
  };

  onDragover = (e: DragEvent) => {
    // Для того, чтобы drop срабатывал
    e.preventDefault();
  };

  onInputChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const files = target.files || null;
    this.setState({
      files
    });
    this.props.onFileSelect?.(files);
  };

  clearInput = (triggerCallback = true) => {
    if (this.$inputElement) {
      this.$inputElement.value = '';
      this.setState({
        files: null
      });
      if (triggerCallback) {
        this.props.onFileSelect?.(null);
      }
    }
  };

  onDidMount() {
    this.attachDocumentEventListeners();
  }

  onWillUnmount() {
    this.detachDocumentEventListeners();
  }

  update(
    state: Partial<IFileInputState>,
    props: Partial<IFileInputStateProps>,
    $rootElement: HTMLElement | null,
    $inputElement: HTMLInputElement | null
  ) {
    applyChanges(this.state, state);
    applyChanges(this.props, props);
    if (this.$rootElement !== $rootElement) {
      this.$rootElement = $rootElement || undefined;
      this.rootListenersEffect.update(this.$rootElement);
    }
    if (this.$inputElement !== $inputElement) {
      this.$inputElement = $inputElement || undefined;
      this.inputListenersEffect.update(this.$inputElement);
    }
  }

  private attachDocumentEventListeners() {
    if (typeof document !== 'undefined') {
      document.addEventListener('dragenter', this.onDragEnter);
      document.addEventListener('dragleave', this.onDragLeave);
      document.addEventListener('dragend', this.onDragEnd);
    }
  }

  private detachDocumentEventListeners() {
    if (typeof document !== 'undefined') {
      document.removeEventListener('dragenter', this.onDragEnter);
      document.removeEventListener('dragleave', this.onDragLeave);
      document.removeEventListener('dragend', this.onDragEnd);
    }
  }

  private rootListenersEffect = new MixinClassEffect(
    (rootRef?: HTMLElement) => {
      if (rootRef) {
        rootRef.addEventListener('dragover', this.onDragover);
        rootRef.addEventListener('drop', this.onDrop);
      }

      return () => {
        if (!rootRef) return;
        rootRef.removeEventListener('dragover', this.onDragover);
        rootRef.removeEventListener('drop', this.onDrop);
      };
    }
  );

  private inputListenersEffect = new MixinClassEffect(
    (inputRef?: HTMLElement) => {
      if (inputRef) {
        inputRef.addEventListener('change', this.onInputChange);
      }

      return () => {
        if (!inputRef) return;
        inputRef.removeEventListener('change', this.onInputChange);
      };
    }
  );
}
