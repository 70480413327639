import { ComponentLinks, FigmaEmbed } from "../../../../src/common";
import { Playground } from 'docz';
import { ScrollArea } from '@hse-design/react';
import * as React from 'react';
export default {
  ComponentLinks,
  FigmaEmbed,
  Playground,
  ScrollArea,
  React
};