/**
 * Позволяет вызвать дополнительные функции на вызов функции fn
 * @param fn
 * @param sideEffects
 */
export const applySideEffects = (
  fn: (args: any) => void,
  ...sideEffects: any[]
) => (args) => {
  sideEffects.forEach((fn) => fn?.());
  return fn?.(args);
};
