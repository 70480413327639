import { MixinClass } from '../utils';

export interface IPage {
  active?: boolean;
  etc?: boolean;
  number: number;
}

export interface IPaginationState {
  pages: IPage[];
}

type Dispatch<A> = (value: Partial<A>) => void;
type SetStateAction<S> = S | ((prevState: S) => S);
type setStateFn = Dispatch<SetStateAction<IPaginationState>>;

export class PaginationState implements MixinClass {
  private setState: setStateFn;
  public onChange: (currentPage: number) => void;
  constructor(
    setState: any,
    onChange: (currentPage: number) => void,
    private totalCount: number,
    private pageSize: number,
    private currentPage: number,
    private showAround: number
  ) {
    this.onChange = onChange;
    this.setState = (changes: Partial<IPaginationState>) =>
      setState((prevState) => ({
        ...prevState,
        ...changes
      }));
    this.updateState();
  }

  public onUpdate = (params: {
    onChange: (currentPage: number) => void;
    totalCount?: number;
    pageSize?: number;
    currentPage?: number;
    showAround?: number;
  }) => {
    (Object.keys(params) as (keyof typeof params)[]).forEach((key) => {
      this[key] = params[key] as any;
    });
    this.updateState();
  };

  public onPrevClick = () => {
    if (this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      this.onChange(this.currentPage);
      this.updateState();
    }
  };

  private updateState() {
    this.setState({ pages: this.pages });
  }

  public onItemClick(pageNumber: number) {
    this.onChange(pageNumber);
    this.currentPage = pageNumber;
    this.updateState();
  }
  private get numOfPages() {
    return Math.ceil(this.totalCount / this.pageSize);
  }

  private get maxNumOfPages() {
    return 4 * this.showAround + 3;
  }

  private get pages(): IPage[] {
    const pages: IPage[] = [];
    if (this.maxNumOfPages > this.numOfPages) {
      for (let i = 1; i <= this.numOfPages; i++) {
        pages.push({
          number: i,
          active: this.currentPage === i
        });
      }
      return pages;
    }
    if (
      this.currentPage < 2 * this.showAround + 1 ||
      this.currentPage <= this.showAround + 3
    ) {
      let i = 1;
      while (
        i <= 2 * this.showAround + 1 ||
        i <= this.currentPage + this.showAround
      ) {
        pages.push({
          number: i,
          active: this.currentPage === i
        });
        i++;
      }
      pages.push({
        number: i,
        etc: true
      });
      i++;
      let currentPage = this.numOfPages - (this.maxNumOfPages - i);
      while (i <= this.maxNumOfPages) {
        pages.push({
          number: currentPage
        });
        currentPage++;
        i++;
      }
      return pages;
    }
    if (
      this.numOfPages - this.currentPage + 1 < 2 * this.showAround + 1 ||
      this.numOfPages - this.currentPage + 1 <= this.showAround + 3
    ) {
      let i = 1;
      while (
        i <= 2 * this.showAround + 1 ||
        this.numOfPages - i + 1 >= this.currentPage - this.showAround
      ) {
        pages.push({
          number: this.numOfPages - i + 1,
          active: this.currentPage === this.numOfPages - i + 1
        });
        i++;
      }
      pages.push({
        number: this.numOfPages - i,
        etc: true
      });
      i++;
      let currentPage = this.maxNumOfPages - i + 1;
      while (i <= this.maxNumOfPages) {
        pages.push({
          number: currentPage
        });
        currentPage--;
        i++;
      }
      return pages.reverse();
    }
    pages.push({
      number: 1
    });

    pages.push({
      number: 2,
      etc: true
    });
    let i = 3;
    let currentPage = this.currentPage - 2 * this.showAround + 1;
    while (i < 3 + 4 * this.showAround - 3 + 2) {
      pages.push({
        number: currentPage,
        active: currentPage === this.currentPage
      });
      i++;
      currentPage++;
    }
    pages.push({
      number: this.numOfPages - 1,
      etc: true
    });
    pages.push({
      number: this.numOfPages
    });
    return pages;
  }

  public onNextClick = () => {
    if (this.currentPage < this.numOfPages) {
      this.currentPage++;
      this.onChange(this.currentPage);
      this.updateState();
    }
  };

  onWillUnmount = () => {};
}
