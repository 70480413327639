import React from 'react';
import * as breakpoints from '@hse-design/tokens/lib/es/breakpoints.module.js';
import * as gridColumnsCount from '@hse-design/tokens/lib/es/gridColumnsCount.module.js';
import * as gridGutters from '@hse-design/tokens/lib/es/gridGutters.module.js';
import * as gridPadding from '@hse-design/tokens/lib/es/gridPadding.module.js';
import * as sidebarWidth from '@hse-design/tokens/lib/es/sidebarWidth.module.js';
import decamelize from 'decamelize';
import s from './GridTable.module.scss';

export function GridTable() {
  const table = [];
  const breakpointNames = [
    'Mobile small',
    'Mobile large',
    'Table small',
    'Table large',
    'Desktop small',
    'Desktop medium',
    'Desktop large'
  ];
  Object.keys(breakpoints).map((tokenName, i) => {
    const width = breakpoints[tokenName];
    const columnsTokenName = tokenName.replace('breakpoint', 'columns');
    const columns = gridColumnsCount[columnsTokenName];
    const gutterTokenName = tokenName.replace('breakpoint', 'gutter');
    const gutter = gridGutters[gutterTokenName];
    const paddingTokenName = tokenName.replace('breakpoint', 'padding');
    const padding = gridPadding[paddingTokenName];
    table.push([
      breakpointNames[i],
      <>
        {width}
        <br />
        {decamelize(tokenName, '-')}
      </>,
      <>
        {columns}
        <br />
        {decamelize(columnsTokenName, '-')}
      </>,
      <>
        {gutter}
        <br />
        {decamelize(gutterTokenName, '-')}
      </>,
      <>
        {padding}
        <br />
        {decamelize(paddingTokenName, '-')}
      </>
    ]);
  });

  return (
    <table className={s.gridTable}>
      <tbody>
        <tr>
          <th className={s.gridTableTd}>Название</th>
          <th className={s.gridTableTd}>Breakpoint</th>
          <th className={s.gridTableTd}>Columns</th>
          <th className={s.gridTableTd}>Gutter</th>
          <th className={s.gridTableTd}>Padding</th>
        </tr>
        {table.map((tr) => (
          <tr key={tr[0]}>
            {tr.map((td, i) => (
              <td key={tr[0] + i} className={s.gridTableTd}>
                {td}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function GridWithSidebarTable() {
  const table = [];
  const breakpointNames = [
    'Table large',
    'Desktop small',
    'Desktop medium',
    'Desktop large',
    'Table large',
    'Desktop small',
    'Desktop medium',
    'Desktop large'
  ];
  Object.keys(sidebarWidth).map((tokenName, i) => {
    const width = sidebarWidth[tokenName];
    const gutterTokenName =
      tokenName
        .replace('sidebarWidth', 'gutter')
        .replace('Fixed', '')
        .replace('Dynamic', '') + 'WithSidebar';
    const gutter = gridGutters[gutterTokenName];
    const paddingTokenName = tokenName
      .replace('sidebarWidth', 'padding')
      .replace('Fixed', 'WithSidebarFixed')
      .replace('Dynamic', 'WithSidebarDynamic');
    const padding = gridPadding[paddingTokenName];
    table.push([
      breakpointNames[i],
      <>
        {width}
        <br />
        {decamelize(tokenName, '-')}
      </>,
      <>
        {gutter}
        <br />
        {decamelize(gutterTokenName, '-')}
      </>,
      <>
        {padding}
        <br />
        {decamelize(paddingTokenName, '-')}
      </>
    ]);
  });

  return (
    <table className={s.gridTable}>
      <tbody>
        <tr>
          <th className={s.gridTableTd}>Название</th>
          <th className={s.gridTableTd}>Ширина боковой панели</th>
          <th className={s.gridTableTd}>Gutter</th>
          <th className={s.gridTableTd}>Padding</th>
        </tr>
        {table.map((tr, iTr) => (
          <tr key={iTr}>
            {tr.map((td, iTd) => (
              <td key={iTd} className={s.gridTableTd}>
                {td}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
