import { LogoColor, LogoDesignSystem, LogoDigital, LogoDigitalCRM, LogoFindRoommate, LogoHseCareer, LogoLang, LogoSmartAssistant, LogoSmartBoss, LogoSmartData, LogoSmartDoc, LogoSmartLms, LogoSmartOffice, LogoSmartPoint, LogoSmartSecurity, LogoSmartScience, LogoSmartReg, LogoSmartEvent } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from "../../../../src/common";
import * as React from 'react';
export default {
  LogoColor,
  LogoDesignSystem,
  LogoDigital,
  LogoDigitalCRM,
  LogoFindRoommate,
  LogoHseCareer,
  LogoLang,
  LogoSmartAssistant,
  LogoSmartBoss,
  LogoSmartData,
  LogoSmartDoc,
  LogoSmartLms,
  LogoSmartOffice,
  LogoSmartPoint,
  LogoSmartSecurity,
  LogoSmartScience,
  LogoSmartReg,
  LogoSmartEvent,
  Playground,
  Props,
  ComponentLinks,
  React
};