export const gutterMobileSmall = "16px";
export const gutterMobileLarge = "16px";
export const gutterTabletSmall = "16px";
export const gutterTabletLarge = "16px";
export const gutterTabletLargeWithSidebar = "16px";
export const gutterDesktopSmall = "24px";
export const gutterDesktopSmallWithSidebar = "16px";
export const gutterDesktopMedium = "32px";
export const gutterDesktopMediumWithSidebar = "24px";
export const gutterDesktopLarge = "32px";
export const gutterDesktopLargeWithSidebar = "24px";