export const colorGrayDusk = "rgb(107, 122, 153)";
export const colorGrayDusk1 = "rgb(107, 122, 153)";
export const colorGrayDusk2 = "rgb(124, 137, 163)";
export const colorGrayDusk3 = "rgb(162, 169, 184)";
export const colorGrayDusk4 = "rgb(192, 198, 209)";
export const colorGrayDusk5 = "rgb(240, 242, 245)";
export const colorGrayMorn = "rgb(211, 216, 230)";
export const colorGrayMorn1 = "rgb(211, 216, 230)";
export const colorGrayMorn2 = "rgb(225, 228, 235)";
export const colorGrayMorn3 = "rgb(237, 239, 243)";
export const colorGrayMorn4 = "rgb(245, 246, 250)";