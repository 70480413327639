import { Playground, Props } from 'docz';
import { IconActionPlus, IconGlobalAlarm, IconButton, IconButtonVariant, Size, Space } from '@hse-design/react';
import { colorAccentSlateblue, colorAccentSlateblue4, colorSystemSuccess } from '@hse-design/tokens';
import { ComponentLinks, FigmaEmbed } from "../../../../src/common";
import * as React from 'react';
export default {
  Playground,
  Props,
  IconActionPlus,
  IconGlobalAlarm,
  IconButton,
  IconButtonVariant,
  Size,
  Space,
  colorAccentSlateblue,
  colorAccentSlateblue4,
  colorSystemSuccess,
  ComponentLinks,
  FigmaEmbed,
  React
};