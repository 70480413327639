import { FooterLinkComponent } from "../../../../src/common/FooterLinkComponent";
import { BaseFooter, Footer, Space, Size, SidebarVariant, IconActionLinkOpen } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from "../../../../src/common";
import * as React from 'react';
export default {
  FooterLinkComponent,
  BaseFooter,
  Footer,
  Space,
  Size,
  SidebarVariant,
  IconActionLinkOpen,
  Playground,
  Props,
  ComponentLinks,
  React
};