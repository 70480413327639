import { Button, Error, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from "../../../../src/common";
import { FigmaEmbed } from "../../../../src/common/FigmaEmbed";
import * as React from 'react';
export default {
  Button,
  Error,
  Space,
  Playground,
  Props,
  ComponentLinks,
  FigmaEmbed,
  React
};