import { LogoDesignSystem, LogoDigital, LogoDigitalCRM, LogoFindRoommate, LogoHseCareer, LogoSmartAssistant, LogoSmartBoss, LogoSmartData, LogoSmartDoc, LogoSmartLms, LogoSmartOffice, LogoSmartPoint, LogoSmartReg, LogoSmartSecurity, LogoSmartEvent } from '@hse-design/react';
import { colorBackgroundGray1 } from '@hse-design/tokens';
import { LogoDemo, P } from "../../../../src/common";
import favicon from '@hse-design/core/lib/assets/favicon/basic.svg';
import * as React from 'react';
export default {
  LogoDesignSystem,
  LogoDigital,
  LogoDigitalCRM,
  LogoFindRoommate,
  LogoHseCareer,
  LogoSmartAssistant,
  LogoSmartBoss,
  LogoSmartData,
  LogoSmartDoc,
  LogoSmartLms,
  LogoSmartOffice,
  LogoSmartPoint,
  LogoSmartReg,
  LogoSmartSecurity,
  LogoSmartEvent,
  colorBackgroundGray1,
  LogoDemo,
  P,
  favicon,
  React
};