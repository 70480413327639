import { MixinClassEffect } from './MixinClassEffect';

export class WindowEventEffect<
  Event extends keyof WindowEventMap
> extends MixinClassEffect<[boolean]> {
  constructor(
    eventName: Event,
    eventHandlerFn: (event: WindowEventMap[Event]) => any
  ) {
    super((active: boolean) => {
      if (active) {
        window.addEventListener(eventName, eventHandlerFn);
      }

      return () => {
        if (!active) return;
        window.removeEventListener(eventName, eventHandlerFn);
      };
    });
  }
}
