export const colorSystemSuccessDeepdark = "rgb(9, 98, 55)";
export const colorSystemSuccessDark = "rgb(34, 139, 69)";
export const colorSystemSuccess = "rgb(40, 199, 93)";
export const colorSystemSuccessLight = "rgb(233, 249, 239)";
export const colorSystemErrorDeepdark = "rgb(185, 33, 46)";
export const colorSystemErrorDark = "rgb(216, 46, 46)";
export const colorSystemError = "rgb(255, 86, 78)";
export const colorSystemErrorLight = "rgb(255, 238, 237)";
export const colorSystemWarningDeepdark = "rgb(143, 87, 20)";
export const colorSystemWarningDark = "rgb(233, 162, 29)";
export const colorSystemWarning = "rgb(248, 183, 63)";
export const colorSystemWarningLight = "rgb(254, 248, 236)";