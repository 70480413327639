import React from 'react';

import * as colorsAccent from '@hse-design/tokens/lib/es/colorsAccent.module.js';
import * as colorsBackground from '@hse-design/tokens/lib/es/colorsBackground.module.js';
import * as colorsBase from '@hse-design/tokens/lib/es/colorsBase.module.js';
import * as colorsBrand from '@hse-design/tokens/lib/es/colorsBrand.module.js';
import * as colorsElementOverlay from '@hse-design/tokens/lib/es/colorsElementOverlay.module.js';
import * as colorsGray from '@hse-design/tokens/lib/es/colorsGray.module.js';
import * as colorsScreenOverlay from '@hse-design/tokens/lib/es/colorsScreenOverlay.module.js';
import * as colorsSystem from '@hse-design/tokens/lib/es/colorsSystem.module.js';

import * as sizing from '@hse-design/tokens/lib/es/sizing.module.js';
import * as spacing from '@hse-design/tokens/lib/es/spacing.module.js';
import * as opacity from '@hse-design/tokens/lib/es/opacity.module.js';
import * as radius from '@hse-design/tokens/lib/es/radius.module.js';
import * as shadow from '@hse-design/tokens/lib/es/shadow.module.js';
import * as breakpoint from '@hse-design/tokens/lib/es/breakpoints.module.js';
import decamelize from 'decamelize';
import rgbHex from 'rgb-hex';
import s from './TokensDemo.module.scss';

const tokens = {
  colorsAccent,
  colorsBackground,
  colorsBase,
  colorsBrand,
  colorsElementOverlay,
  colorsGray,
  colorsScreenOverlay,
  colorsSystem,
  sizing,
  spacing,
  radius,
  opacity,
  shadow,
  breakpoint
};

function TokensDemo({ tokenName, demoStylesBuilder }) {
  const tokenValues = tokens[tokenName];
  const isColor = tokenName.includes('colors');
  const isSpacing = tokenName === 'spacing';

  return (
    <table>
      <tbody>
        {typeof tokenValues === 'object' &&
          Object.keys(tokenValues).map((tokenItem) => (
            <tr key={tokenItem}>
              {demoStylesBuilder && (
                <td className={s.tokenDemoContainer}>
                  <div
                    className={s.tokenDemo}
                    style={demoStylesBuilder(tokenItem)}
                  />
                  {isSpacing && <div className={s.tokenDemo} />}
                </td>
              )}
              <td className={s.tokenInfo}>
                {decamelize(
                  isSpacing
                    ? // Замена нужна для возвращения корректного названия спейсинга. Без него spacing3XSmall превращается в 'spacing-small3-x', ожидаемый же результат 'spacing-small-3x'
                      // RegExp заменяет все строки вида <number>X на -<number>X ('30X' становится '-30x', 'spacingSmall30x' становится 'spacingSmall-30x')
                      // Если это токен не спейсинга, добавляет "-" перед цифрами
                      tokenItem.replace(/([0-9]+)X{1}/gi, '-$1x')
                    : tokenItem.replace(/([0-9]+)/gi, '-$1'),
                  '-'
                )}
              </td>
              <td className={s.tokenInfo}>{tokenValues[tokenItem]}</td>
              {isColor && (
                <td className={s.tokenInfo}>
                  {'#' + rgbHex(tokenValues[tokenItem]).toUpperCase()}
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export const ColorsDemo = ({ palette = '' }) => {
  const toUpperFirst = ([first, ...rest]) => `${first.toUpperCase()}${rest}`;
  const tokenName = `colors${toUpperFirst(palette)}`;

  return (
    <TokensDemo
      tokenName={tokenName}
      demoStylesBuilder={(tokenItem) => ({
        backgroundColor: tokens[tokenName][tokenItem],
        boxShadow: `0 0 2px rgba(0, 0, 0, .25)`
      })}
    />
  );
};

export const SizingDemo = () => (
  <TokensDemo
    tokenName="sizing"
    demoStylesBuilder={(tokenItem) => ({
      width: sizing[tokenItem],
      height: sizing[tokenItem]
    })}
  />
);

export const SpacingDemo = () => (
  <TokensDemo
    tokenName="spacing"
    demoStylesBuilder={(tokenItem) => ({
      marginRight: spacing[tokenItem]
    })}
  />
);

export const OpacityDemo = () => (
  <TokensDemo
    tokenName="opacity"
    demoStylesBuilder={(tokenItem) => ({
      opacity: opacity[tokenItem]
    })}
  />
);

export const RadiusDemo = () => (
  <TokensDemo
    tokenName="radius"
    demoStylesBuilder={(tokenItem) => ({
      borderRadius: radius[tokenItem]
    })}
  />
);

export const ShadowDemo = () => (
  <TokensDemo
    tokenName="shadow"
    demoStylesBuilder={(tokenItem) => ({
      display: 'block',
      boxShadow: shadow[tokenItem],
      background: '#fff',
      width: '100px',
      height: '100px'
    })}
  />
);

export const BreakpointDemo = () => {
  return <TokensDemo tokenName="breakpoint" />;
};
