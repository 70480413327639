import React, { IframeHTMLAttributes, useMemo } from 'react';
import { figmaUrl } from '../config';

interface Props {
  node?: string;
  width?: number | string;
  height?: number | string;
}

export function FigmaEmbed({
  node = '',
  width = '100%',
  height = 600,
  ...props
}: Props) {
  const url = useMemo(
    () =>
      'https://www.figma.com/embed?embed_host=share&url=' +
      encodeURIComponent(figmaUrl + node),
    [node]
  );
  return (
    <iframe
      src={url}
      width={width}
      height={height}
      style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}
      allowFullScreen
      loading={'lazy'}
      {...props}
    />
  );
}
