import { Optional } from '../../utils/types';

export enum SelectSearchableVariant {
  dropdown = 'dropdown',
  inline = 'inline'
}

export type SelectSearchable = false | SelectSearchableVariant;

export type IOptionValue = string;

export interface IOption {
  /**
   * Отображаемое название
   */
  label: string;
  /**
   * Уникальный идентификатор (значение) опции
   */
  value: IOptionValue;
  /**
   * Неактивная опция
   */
  inactive?: boolean;
  /**
   * Добавляет разделитель перед опцией
   */
  divided?: boolean;
  /**
   * Идентификатор группы опций.
   * Предназначен для внутреннего использования.
   * Для задания группировки опций используйте интерфейс IOptionGroup вместо IOption.
   */
  group?: string;
  /**
   * Левая иконка
   */
  leftIcon?: any;
  /**
   * Правая иконка
   */
  rightIcon?: any;
}

export interface IOptionGroup<Option extends IOption = IOption> {
  /**
   * Название группы
   */
  label: string;
  /**
   * Массив опций группы
   */
  options: Option[];
}

export type GetOptionsFn<Option extends IOption = IOption> = (
  searchValue: string
) => Promise<Option[]>;

export type ISelectStatePropsValue<Option extends IOption = IOption> =
  | {
      /**
       * Устанавливает возможность множественного выбора опций Select
       */
      multiple?: false;
      /**
       * Значение селекта.
       * При использовании getOptions следует передавать значение в виде
       * объекта опции типа IOption
       */
      value: null | Option['value'] | Option;
      /**
       * Обработчик события изменения значения Select.
       * Принимает на вход значение опции.
       * Вместо него рекомендуется использовать onChangeOption.
       * @deprecated
       */
      onChange?: (newValue: null | Option['value']) => void;
      /**
       * Обработчик события изменения значения Select
       */
      onChangeOption?: (newValue: null | Option) => void;
      /**
       * Обработчик события нажатия на кнопку очистки значения,
       * которая отображается при clearable.
       * Вы можете определить в нём произвольную логику.
       * Если его не передать, то будет вызван onChange.
       *
       * @param emptyValue - пустое значение, соответствующее multiple
       */
      onClear?: (emptyValue: null) => void;
    }
  | {
      /**
       * Устанавливает возможность множественного выбора опций Select
       */
      multiple: true;
      /**
       * Выбранные значения (в случае, если multiple установлен в true) Select.
       * При использовании getOptions следует передавать значение в виде
       * объектов опций типа IOption
       */
      value: (Option['value'] | Option)[];
      /**
       * Обработчик события изменения значения Select.
       * Принимает на вход опцию.
       * Вместо него рекомендуется использовать onChangeOption.
       * @deprecated
       */
      onChange?: (newValue: Option['value'][]) => void;
      /**
       * Обработчик события изменения значения Select
       */
      onChangeOption?: (newValue: Option[]) => void;
      /**
       * Обработчик события нажатия на кнопку очистки значения,
       * которая отображается при clearable.
       * Вы можете определить в нём произвольную логику.
       * Если его не передать, то будет вызван onChange.
       *
       * @param emptyValue - пустое значение, соответствующее multiple
       */
      onClear?: (emptyValue: []) => void;
    };

export interface ISelectStatePropsProps<Option extends IOption = IOption> {
  /**
   * Включает постоянное отображение всплывающего меню.
   * Полезно для отладки
   */
  permanent?: boolean;
  /**
   * Включает поднятие выбранных элементов наверх
   */
  selectedFirst?: boolean;
  /**
   * Включает поле поиска и определяет его положение:
   * - inline - внутри основного контейнера
   * - dropdown - внутри всплывающего меню
   *
   * Для multiple может быть использован только dropdown
   */
  searchable?: SelectSearchable;
  /**
   * Включает возможность сбросить выбранное значение.
   * При сбросе вызывается колбек onClear
   */
  clearable?: boolean;
  /**
   * Массив доступных опций.
   * Не передавайте его, если используете getOptions
   */
  options: Option[];
  /**
   * Функция для динамического получения опций. Принимает на вход текущее значение для поиска,
   * возвращает это значение и промис, разрешающийся в массив опций, удовлетворяющих запросу для поиска.
   *
   * При использовании getOptions встроенная фильтрация опций не применяется,
   * так как эта функция предназначена для получения уже отфильтрованного списка
   * из внешнего источника (например, из запроса на бекенд).
   *
   * Если запрос к бекенду возвращает полный перечень допустимых значений
   * и не поддерживает фильтрацию, то не используйте getOptions и передайте
   * этот перечень через проп options.
   */
  getOptions?: GetOptionsFn<Option>;
  /**
   * Текст, который по умолчанию выводится, если случилась ошибка в getOptions
   */
  defaultErrorMessage?: string;
}

export type ISelectStateProps<
  Option extends IOption = IOption
> = ISelectStatePropsProps<Option> & ISelectStatePropsValue<Option>;

export interface ISelectState<Option extends IOption = IOption> {
  /**
   * Если не getOptions, то это options, отфильтрованные с использованием
   * searchValue.
   * Если getOptions, то это результат вызова getOptions.
   * Если selectedFirst, то выбранные значения перемещаются наверх
   * с сохранением порядка из value. Порядок остальных значений сохраняется
   * как в options/getOptions.
   */
  filteredOptions: Option[];
  /**
   * Выбранные опции, полученные из value
   */
  selectedOptions: Option[];
  /**
   * Значение поисковой строки
   */
  searchValue: string;
  /**
   * Открыто ли всплывающее меню
   */
  menuOpen: boolean;
  /**
   * Опция на которой клавиатурный фокус
   */
  focusedOption: Option | null;
  /**
   * Тег (выбранная опция), на котором клавиатурный фокус.
   * Не используется
   *
   * @deprecated
   */
  focusedTagI: number;
  /**
   * Находится ли кнопка очистки в фокусе
   */
  clearButtonFocused: boolean;
  /**
   * Находится ли поисковый инпут в фокусе
   */
  searchInputFocused: boolean;
  /**
   * Находится ли сам компонент в фокусе
   */
  rootFocused: boolean;
  /**
   * Идёт ли загрузка данных при использовании getOptions
   */
  loading: boolean;
  /**
   * Произошла ли ошибка при использовании getOptions
   */
  error: boolean;
  /**
   * Отображаемый текст ошибки при использовании getOptions
   */
  errorMessage: string;
}

export interface ISelectStateCallbacks<Option extends IOption = IOption> {
  onClearClick: (e: Event) => void;
  onKeyDown: (e: KeyboardEvent) => void;
  onFocus: () => void;
  onBlur: () => void;
  onValueClick: (e: MouseEvent) => void;
  onSearchChange: (newValue: string) => void;
  onSearchInputFocus: () => void;
  onSearchInputBlur: () => void;
  onOptionClick: (option: Option) => void;
  onOptionMouseEnter: (option: Option) => void;
  onClickOutside: (e: MouseEvent) => void;
}

export interface ISelectRefs {
  container: Optional<HTMLDivElement>;
  searchInput: Optional<HTMLInputElement>;
  dropdown: Optional<HTMLDivElement>;
  multipleValues: Optional<HTMLDivElement>;
  dropdownMenuItems: Optional<HTMLDivElement[]>;
  dropdownMenuItemsContainer: Optional<HTMLDivElement>;
}

export interface ISelectComputed {
  showTags: boolean;
  singleValueNotSelected: boolean;
  showInputInValueField: boolean;
  showSelectedValueInField: boolean;
  showPlaceholderInField: boolean;
  showClearIcon: boolean;
  showNothingFoundPlaceholder: boolean;
  searchable?: SelectSearchable;
}
