import { useState } from 'react';
import { RichInput, Space, TextArea } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { FigmaEmbed, ComponentLinks } from "../../../../src/common";
import * as React from 'react';
export default {
  useState,
  RichInput,
  Space,
  TextArea,
  Playground,
  Props,
  FigmaEmbed,
  ComponentLinks,
  React
};