import React from 'react';
import s from './LogoDemo.module.scss';

export function LogoDemo({ logo, logoProps, label = '' }) {
  const LogoComponent = logo;
  return (
    <div className={s.logoContainer}>
      <LogoComponent {...logoProps} />
      <p className={s.logoLabel}>
        {label && (
          <>
            {label}
            <br />
          </>
        )}
      </p>
    </div>
  );
}
