import { useState } from 'react';
import { Playground, Props } from 'docz';
import { LinkAbbr, Size, Space } from '@hse-design/react';
import { FigmaEmbed, ComponentLinks, P } from "../../../../src/common";
import { colorBrandDark } from '@hse-design/tokens';
import { spacingSmall2X } from '@hse-design/tokens';
import * as React from 'react';
export default {
  useState,
  Playground,
  Props,
  LinkAbbr,
  Size,
  Space,
  FigmaEmbed,
  ComponentLinks,
  P,
  colorBrandDark,
  spacingSmall2X,
  React
};