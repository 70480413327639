import { MixinClass } from '../../utils/MixinClass';
import { Optional } from '../../utils/types';
import { Bubble } from './Bubble';

export class BubbleManager implements MixinClass {
  root: Optional<HTMLDivElement> = null;
  bubbles: { [key: number]: Bubble } = {};
  bubbleCounter = 0;

  static create() {
    return new BubbleManager();
  }

  onWillUnmount() {
    for (const bubble of Object.values(this.bubbles)) {
      bubble.clear();
    }
  }

  update({ root }: { root: Optional<HTMLDivElement> }) {
    this.root = root;
  }

  createBubble() {
    if (!this.root) return;

    const id = ++this.bubbleCounter;
    const bubble = new Bubble(id, this.onRemoveBubble);
    this.root.append(bubble.div!);
    this.bubbles[id] = bubble;
    return id;
  }

  fadeBubbles() {
    for (const bubble of Object.values(this.bubbles)) {
      bubble.fade();
    }
  }

  onRemoveBubble = (id: number) => {
    delete this.bubbles[id];
  };
}
