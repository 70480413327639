/**
 * Создает копию объекта,удаляя все неопределенные поляю
 * @param obj
 */
export const filterNulls = (
  obj: { [p: string]: any } | undefined
): { [p: string]: any } => {
  if (typeof obj === 'object')
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );
  return {};
};
