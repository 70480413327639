import {
  isPrintableKeyDown,
  MixinClass,
  SetStateFn,
  SingleTimeoutManager
} from '../../utils';
import { Key } from 'ts-keycode-enum';
import { filterSearch, isTruthy } from '@proscom/ui-utils';
import { applyChanges } from '../../utils/changes';
import { setState } from '../../utils/setState';
import {
  IOption,
  ISelectComputed,
  ISelectRefs,
  ISelectState,
  ISelectStateCallbacks,
  ISelectStateProps,
  SelectSearchableVariant
} from './types';
import { findOrCreateOption, matchOption } from './helpers';

export class SelectState<Option extends IOption = IOption>
  implements MixinClass, ISelectStateCallbacks<Option> {
  static initialState = {
    filteredOptions: [],
    selectedOptions: [],
    searchValue: '',
    menuOpen: false,
    focusedOption: null,
    focusedTagI: -1,
    clearButtonFocused: false,
    searchInputFocused: false,
    rootFocused: false,
    loading: false,
    error: false,
    errorMessage: ''
  };

  static defaultProps = {
    multiple: false as const,
    selectedFirst: false,
    value: null,
    options: []
  };

  // props
  props: ISelectStateProps<Option> = { ...SelectState.defaultProps };
  computed: ISelectComputed = {} as any;

  // state
  state: ISelectState<Option> = { ...SelectState.initialState };
  setState: (changes: Partial<ISelectState<Option>>) => void;

  /**
   * Декоратор выводящий предупреждение в случае дублирования values
   * @example
   * this.setState = this.logDecorator(setState(componentSetState));
   * @param setState
   */
  logDecorator = (
    setState: (changes: Partial<ISelectState<Option>>) => void
  ) => (changes) => {
    if (changes?.filteredOptions?.length) {
      const values = new Set();
      for (const { value } of changes?.filteredOptions) {
        if (values.has(value)) {
          console.warn(
            'Select не поддерживает использование с дублирующимися опциями.'
          );
        }
        values.add(value);
      }
    }
    return setState.call(this, changes);
  };

  // internal
  refs: ISelectRefs = {
    container: null,
    searchInput: null,
    dropdown: null,
    multipleValues: null,
    dropdownMenuItems: [],
    dropdownMenuItemsContainer: null
  };

  timeoutManager = new SingleTimeoutManager();

  constructor(componentSetState: SetStateFn<ISelectState<Option>>) {
    this.setState = this.logDecorator(setState(componentSetState));
  }

  protected get isClearable() {
    return this.props.clearable || this.props.onClear;
  }

  /**
   * Поднимает выбранные опции наверх, если задано selectedFirst и multiple
   */
  protected getOrderedOptions(options: Option[]) {
    if (
      this.props.selectedFirst &&
      this.props.multiple &&
      this.props.value?.length > 0
    ) {
      const value = this.props.value;
      const nonValueOptions = options.filter((o) => {
        return !value.some((v) => matchOption(v, o));
      });
      const valueOptions = value
        .map((v) => options.find((o) => matchOption(v, o)))
        .filter(isTruthy);
      return [...valueOptions, ...nonValueOptions];
    }
    return options;
  }

  /**
   * Если getOptions не используется, то фильтрует список опций по поисковому
   * ключу.
   * Если getOptions используется, то вызывает его с новым значением
   * поискового ключа.
   *
   * Поднимает выбранные опции наверх, если задано selectedFirst и multiple
   */
  protected updateFilteredOptions() {
    const { searchValue } = this.state;
    if (!this.props.getOptions) {
      let newFilteredOptions: Option[] = [];
      if (this.computed.searchable) {
        newFilteredOptions = this.getFilteredOptions(searchValue);
      } else {
        newFilteredOptions = this.props.options;
      }
      return this.setState({
        filteredOptions: this.getOrderedOptions(newFilteredOptions)
      });
    }

    this.setState({
      loading: true
    });

    this.props
      .getOptions(searchValue)
      .then((options) => {
        // Если новое значение поиска неактуально – ничего не делаем
        if (searchValue !== this.state.searchValue) return;
        if (!Array.isArray(options)) {
          throw new Error(
            'Select. Value returned from getOptions must be an array of options'
          );
        }
        this.setState({
          filteredOptions: this.getOrderedOptions(options),
          loading: false,
          errorMessage: '',
          error: false
        });
      })
      .catch((error) => {
        // Если новое значение поиска неактуально – ничего не делаем
        if (searchValue !== this.state.searchValue) return;
        let errorMessage =
          this.props.defaultErrorMessage || 'Ничего не найдено';
        switch (typeof error) {
          case 'string':
          case 'number':
            errorMessage = String(error);
            break;
          case 'object':
            if ('message' in error) {
              errorMessage = error.message;
            }
            break;
        }

        this.setState({
          filteredOptions: [],
          loading: false,
          errorMessage,
          error
        });
      });
  }

  /**
   * Открывает дропдаун меню
   */
  protected openMenu = () => {
    this.setState({
      menuOpen: true,
      rootFocused: true,
      clearButtonFocused: false
    });
    this.setFocusedOption(this.state.selectedOptions?.[0] || null);
    // Ожидаем, что на следующий тик инпут будет доступен
    setTimeout(() => {
      this.focusSearchInput();
    }, 0);
  };

  /**
   * Закрывает дропдаун меню
   */
  protected closeMenu = () => {
    this.refs.searchInput?.blur();
    this.setState({
      focusedOption: null,
      menuOpen: false,
      searchValue: ''
    });
  };

  /**
   * Меняет текущую сфокусированную опцию
   * @param option Опция
   * @param scrollToOption Надо ли скроллить к опции
   */
  protected setFocusedOption = (
    option: Option | null = null,
    scrollToOption = true
  ) => {
    this.setState({
      focusedOption: option
    });
    if (scrollToOption && option) {
      this.scrollToItem(option);
    }
  };

  /**
   * Меняет тег для фокуса. Не используется
   * @param tagI
   * @deprecated
   */
  protected setFocusedTag = (tagI: number = -1) => {
    this.setState({
      focusedTagI: tagI
    });
  };

  /**
   * Меняет текущую выбранную опцию
   * @param option
   */
  protected changeValue = (option: Option) => {
    if (this.props.multiple) {
      let newValue: Option[] = [];

      const selectedOptionIndex = this.state.selectedOptions.findIndex(
        (value) => matchOption(value, option)
      );
      if (selectedOptionIndex >= 0) {
        newValue = this.state.selectedOptions.filter(
          (value, iValue) => iValue !== selectedOptionIndex
        );
      } else {
        newValue = [...this.state.selectedOptions, option];
      }

      this.props.onChange?.(newValue.map((v) => v.value));
      this.props.onChangeOption?.(newValue);
    } else {
      this.props.onChange?.(option.value);
      this.props.onChangeOption?.(option);
      // При выборе одиночной опции очищаем поле поиска, чтобы человеку было удобнее начать поиск в следующий раз
      this.onSearchChange('');
    }
  };

  /**
   * Возвращает отфильтрованные опции по строке
   * @param searchValue строка для поиска
   */
  protected getFilteredOptions(searchValue: string) {
    return filterSearch(searchValue, this.props.options, (o) => o.label);
  }

  /**
   * Возвращает индекс переданной опции
   * @param option Опция
   */
  protected getOptionIndex(option: Option | null) {
    if (option === null) return -1;
    return this.state.filteredOptions.findIndex(
      (v) => v.value === option.value
    );
  }

  /**
   * Возвращает предыдущую активную опцию
   * @param option Опция
   */
  protected getPreviousActiveFilteredOption = (option: Option | null) => {
    let previousOptionI = this.getOptionIndex(option) - 1;
    for (
      let checkedOptions = 0;
      checkedOptions < this.state.filteredOptions.length;
      checkedOptions++
    ) {
      if (previousOptionI < 0) {
        previousOptionI = this.state.filteredOptions.length - 1;
      }
      if (!this.state.filteredOptions[previousOptionI].inactive) {
        return this.state.filteredOptions[previousOptionI];
      }
      previousOptionI--;
    }
    return null;
  };

  /**
   * Возвращает следующую активную опцию
   * @param option - Опция
   */
  protected getNextActiveFilteredOption = (option: Option | null) => {
    let nextOptionI = this.getOptionIndex(option) + 1;
    for (
      let checkedOptions = 0;
      checkedOptions < this.state.filteredOptions.length;
      checkedOptions++
    ) {
      if (nextOptionI >= this.state.filteredOptions.length) {
        nextOptionI = 0;
      }
      if (!this.state.filteredOptions[nextOptionI].inactive) {
        return this.state.filteredOptions[nextOptionI];
      }
      nextOptionI++;
    }
    return null;
  };

  /**
   * Скролл к опции
   * @param option Опция
   */
  protected scrollToItem = (option: Option) => {
    if (!this.refs.dropdown) return;
    const optionIndex = this.getOptionIndex(option);
    const optionsRefs = this.refs.dropdownMenuItems;
    const itemsContainerRef = this.refs.dropdownMenuItemsContainer;
    if (!optionsRefs?.length || !itemsContainerRef) return;
    if (optionsRefs.length < optionIndex) return;
    const optionRef = optionsRefs[Math.max(optionIndex, 0)];
    if (optionRef) {
      const { top } = optionRef.getBoundingClientRect();
      itemsContainerRef.scrollTo({
        top: itemsContainerRef.scrollTop - itemsContainerRef.clientHeight + top
      });
    }
  };

  /**
   * Изменить состояние фокуса кнопки очистки
   * @param focus Фокус на кнопке
   */
  protected setClearButtonFocused(focus: boolean) {
    this.setState({
      clearButtonFocused: focus
    });
  }

  /**
   * Сфокусироваться на поисковой строке
   */
  protected focusSearchInput() {
    this.refs.searchInput?.focus();
  }

  /**
   * Сфокусироваться на контейнере компонента
   */
  protected focusContainer() {
    this.refs.container?.focus();
  }

  public onWillUnmount = () => this.timeoutManager.clear();

  /**
   * Рассчитывает производные значения на основе стейта и пропов.
   * Не должна содержать побочных эффектов
   */
  compute = (state: ISelectState<Option>, props: ISelectStateProps<Option>) => {
    this.computed.searchable = props.multiple
      ? props.searchable && SelectSearchableVariant.dropdown
      : props.searchable;
    this.computed.showTags =
      (props.multiple &&
        Array.isArray(props.value) &&
        props.value.length > 0) ||
      false;
    this.computed.singleValueNotSelected = !props.multiple && !props.value;
    this.computed.showInputInValueField =
      !this.computed.showTags &&
      this.computed.searchable === SelectSearchableVariant.inline &&
      (this.computed.singleValueNotSelected ||
        !props.value ||
        (state.menuOpen && !props.permanent));
    this.computed.showSelectedValueInField =
      !props.multiple && !this.computed.showInputInValueField && !!props.value;
    this.computed.showClearIcon =
      (props.clearable &&
        (props.multiple
          ? props.value && props.value.length > 0
          : !!props.value)) ||
      false;
    this.computed.showNothingFoundPlaceholder =
      !state.loading && state.filteredOptions.length === 0;
    this.computed.showPlaceholderInField =
      (props.multiple && (!props.value || props.value.length === 0)) ||
      (!props.multiple &&
        !this.computed.showInputInValueField &&
        !this.computed.showSelectedValueInField);

    return this.computed;
  };

  /**
   * Колбек на обновление компонента. Следует вызывать после `update` компонента
   * (если изменился DOM / внутреннее состояние / пропы)
   * @param state Обновленный state
   * @param props Обновленные пропы
   * @param refs Ссылки на HTML элементы
   */
  public update(
    state: ISelectState<Option>,
    props: ISelectStateProps<Option>,
    refs: ISelectRefs
  ) {
    const valueOrOptionsChanged =
      this.props.value !== props.value || this.props.options !== props.options;

    const shouldUpdateFilteredOptions =
      this.state.searchValue !== state.searchValue ||
      this.props.options !== props.options ||
      (this.props.selectedFirst && this.props.value !== props.value);
    applyChanges(this.state, state);
    applyChanges(this.props, props);
    applyChanges(this.refs, refs);

    if (valueOrOptionsChanged) {
      let selectedOptions: Option[] = [];
      if (Array.isArray(this.props.value)) {
        selectedOptions = this.props.value
          .map((v) => findOrCreateOption(v, this.props.options))
          .filter(isTruthy);
      } else {
        const v = this.props.value;
        const selectedOption = findOrCreateOption(v, this.props.options);
        if (selectedOption) {
          selectedOptions = [selectedOption];
        } else {
          selectedOptions = [];
        }
      }
      this.setState({
        selectedOptions
      });
    }

    if (this.props.value) {
      if (this.props.multiple) {
        console.assert(
          Array.isArray(this.props.value),
          'Select value should be an array for multiselect'
        );
      } else {
        console.assert(
          !Array.isArray(this.props.value),
          'Select value should be a string for non-multi select'
        );
      }
    }

    if (shouldUpdateFilteredOptions) {
      this.updateFilteredOptions();
    }

    if (this.props.permanent && !this.state.menuOpen) {
      this.openMenu();
    }
  }

  /**
   * Обработчик нажатия на кнопку у компонента. Содержит всю логику клавиатурной навигации
   * @param e KeyboardEvent
   */
  public onKeyDown(e: KeyboardEvent) {
    // let newFocusedTag;
    const isToggleKey = e.keyCode === Key.Space;
    const isKeyUp = e.keyCode === Key.UpArrow;
    const isKeyDown = e.keyCode === Key.DownArrow;
    if (isToggleKey) {
      if (!this.state.menuOpen) {
        // При открытии меню с помощью пробела отменяем добавление пробела в поле поиска
        e.preventDefault();
        this.openMenu();
        return;
      }
      // Пробел выполняет управляющую функцию, только если поле поиска пустое, иначе продолжает ввод значения для поиска
      if (this.state.searchValue === '') {
        e.preventDefault();
        if (this.state.focusedOption !== null) {
          if (this.props.selectedFirst) {
            const nextActive = this.getNextActiveFilteredOption(
              this.state.focusedOption
            );
            if (nextActive) {
              this.setFocusedOption(nextActive);
            }
          }
          this.changeValue(this.state.focusedOption);
        }
        // Закрываем меню после выбора опции при одиночном выборе
        if (!this.props.permanent && !this.props.multiple) {
          this.closeMenu();
        }
      }
    } else if (isPrintableKeyDown(e)) {
      // Если нажата не управляющая клавиша (т.е. обозначающая символ для ввода) и меню закрыто, открываем меню для поиска вводимого значения
      if (!this.state.menuOpen && this.computed.searchable) {
        e.stopPropagation();
        this.openMenu();
        if (this.computed.searchable && !this.computed.showInputInValueField) {
          this.setState({
            searchValue: e.key
          });
        }
      }
    }
    if (e.keyCode === Key.Enter) {
      e.preventDefault();
      this.focusContainer();
      if (this.state.clearButtonFocused) {
        this.onClearClick();
        this.openMenu();
        return;
      }
      if (this.state.menuOpen) {
        if (this.state.focusedOption !== null) {
          this.changeValue(this.state.focusedOption);
        }
        if (!this.props.permanent) {
          this.focusContainer();
          this.closeMenu();
        }
      }
    }
    if (e.keyCode === Key.Escape) {
      this.focusContainer();
      this.closeMenu();
    }
    if (isKeyUp || isKeyDown) {
      e.preventDefault(); // Чтобы курсор не перескакивал в начало input
      if (this.state.menuOpen) {
        const getOptionFn = isKeyUp
          ? this.getPreviousActiveFilteredOption
          : this.getNextActiveFilteredOption;
        const newOption = getOptionFn(this.state.focusedOption);
        this.setClearButtonFocused(false);
        this.setFocusedOption(newOption);
        if (newOption) this.scrollToItem(newOption);
      } else {
        this.openMenu();
      }
    }
    if (e.keyCode === Key.LeftArrow) {
      if (this.props.multiple) {
        if (this.state.clearButtonFocused) {
          this.setClearButtonFocused(false);
          // this.setFocusedTag((this.state.tagsCount || 0) - 1);
        } else if (this.state.focusedTagI === 0) {
          if (this.isClearable) {
            this.setClearButtonFocused(true);
            // this.setFocusedTag(-1);
          } else {
            // this.setFocusedTag((this.state.tagsCount || 0) - 1);
          }
        } else if (this.state.focusedTagI > 0) {
          // this.setFocusedTag(this.state.focusedTagI - 1);
        }
      } else {
        if (this.computed.showClearIcon && !this.state.searchInputFocused) {
          if (this.state.clearButtonFocused) {
            this.setClearButtonFocused(false);
          } else {
            this.setClearButtonFocused(true);
            this.setFocusedOption(null);
          }
        }
      }
    }
    if (e.keyCode === Key.RightArrow) {
      if (this.props.multiple) {
        if (this.state.clearButtonFocused) {
          this.setClearButtonFocused(false);
          // this.setFocusedTag(0);
        } else if (
          this.state.focusedTagI >= /*(this.state.tagsCount || 0)*/ -1
        ) {
          if (this.computed.showClearIcon) {
            this.setClearButtonFocused(true);
            // this.setFocusedTag(-1);
          } else {
            // this.setFocusedTag(0);
          }
        } else {
          // this.setFocusedTag(this.state.focusedTagI + 1);
        }
      } else {
        if (this.computed.showClearIcon && !this.state.searchInputFocused) {
          if (this.state.clearButtonFocused) {
            this.setClearButtonFocused(false);
          } else {
            this.setClearButtonFocused(true);
            this.setFocusedOption(null);
          }
        }
      }
    }
    if (e.keyCode === Key.Backspace) {
      if (this.state.focusedTagI !== -1 && this.props.multiple) {
        if (!Array.isArray(this.props.value)) {
          return console.error('Expected Select value to be an array');
        }
        const newValue = this.state.selectedOptions.filter((value, iValue) => {
          return iValue !== this.state.focusedTagI;
        });
        this.props.onChange?.(newValue.map((v) => v.value));
        this.props.onChangeOption?.(newValue);
        // this.setFocusedTag(this.state.focusedTagI - 1);
      }
    }
  }

  /**
   * Обработчик события `focus` у компонента
   */
  public onFocus = () => {
    this.setState({
      rootFocused: true
    });
    // При получении фокуса корневым div перебрасываем фокус на input,
    // если компонент searchable
    this.focusSearchInput();
  };

  /**
   * Обработчик события `blur` у компонента
   */
  public onBlur = () => {
    this.setState({
      rootFocused: false,
      clearButtonFocused: false
    });
    // this.setFocusedTag(-1);
    // Если input не получил фокус, значит фокус перешел за пределы компонента Select
    this.timeoutManager.set(() => {
      if (!this.state.rootFocused && !this.state.searchInputFocused) {
        if (this.props.permanent) {
          this.setFocusedOption(null);
        } else {
          this.closeMenu();
          this.setState({
            searchValue: ''
          });
        }
      }
    }, 0);
  };

  /**
   * Обработчик события `focus` у поисковой строки
   */
  public onSearchInputFocus = () => {
    this.setState({
      searchInputFocused: true
    });
  };

  /**
   * Обработчик события `blur` у поисковой строки
   */
  public onSearchInputBlur = () => {
    this.setState({
      searchInputFocused: false
    });
  };

  /**
   * Обработчик изменения текущего значения поисковой строки
   * @param searchValue новое значение
   */
  public onSearchChange = (searchValue: string) => {
    this.setState({
      searchValue
    });
  };

  /**
   * Обработчик нажатия на `input` с текущим значением
   * @param ev MouseEvent
   */
  public onValueClick = (ev: MouseEvent) => {
    if (!this.props.permanent) {
      if (this.state.menuOpen) {
        ev.preventDefault();
        ev.stopPropagation();
        this.closeMenu();
      } else {
        this.openMenu();
      }
    }
  };

  /**
   * Обработчик нажатия на опцию
   * @param option нажатая опция
   */
  public onOptionClick = (option: Option) => {
    // Если дропдаун открыли с помощью клавиатуры, но управляем дальше мышкой – нам нужно убрать фокус
    this.setFocusedOption(null);

    this.changeValue(option);
    if (!this.props.multiple && !this.props.permanent) {
      this.focusContainer();
      this.closeMenu();
    }
  };

  /**
   * Обработчик `mouseenter` на отдельную опцию
   * @param option
   */
  public onOptionMouseEnter = (option: Option) => {
    this.setFocusedOption(option, false);
  };

  /**
   * Обработчик нажатия на клик вне компонента Select
   * @param e MouseEvent
   */
  public onClickOutside = (e: MouseEvent) => {
    this.onBlur();
  };

  /**
   * Обработчик нажатия на кнопку очистки
   */
  public onClearClick = () => {
    this.setClearButtonFocused(false);
    if (this.props.multiple) {
      if (this.props.onClear) {
        this.props.onClear([]);
      } else {
        this.props.onChange?.([]);
        this.props.onChangeOption?.([]);
      }
    } else {
      if (this.props.onClear) {
        this.props.onClear(null);
      } else {
        this.props.onChange?.(null);
        this.props.onChangeOption?.(null);
      }
    }
  };

  /**
   * Обработчик события `focus` у кнопки очистки
   */
  public onClearFocus = (e: FocusEvent) => {
    if ((e.target as HTMLElement)?.classList.contains('focus-visible')) {
      this.setState({
        clearButtonFocused: true
      });
    }
  };

  /**
   * Обработчик события `blur` у кнопки очистки
   */
  public onClearBlur = () => {
    this.setState({
      clearButtonFocused: false
    });
  };
}
